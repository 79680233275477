import {
  gql,
  DocumentNode,
  TypedDocumentNode,

} from "@apollo/client";
import { SetupData, EmployeeInput, BulkCaseCreateInput, ApplicationResponse } from "services/Interfaces";

export enum BenefitTier {
  individual = "individual",
  family = "family",
  none = "none",
}

interface _Mutations {
  EMAIL_QUOTE: DocumentNode,
  setUserClaims: DocumentNode,
  contactUs: DocumentNode
  setPublicToken: TypedDocumentNode<{ hris: { merge: { setPublicToken: boolean } } }, { publicToken: string, companyId: string }>,
  loadOpportunity: TypedDocumentNode<{ loadOpportunity: boolean }, { opportunityId: string }>,
  syncHris: TypedDocumentNode<{ syncHris: boolean }, { companyId: string }>,
  connectUserToCompany: TypedDocumentNode<{ connectUserToCompany: boolean }, { companyId: string, userId: string, inviteId: string }>,
  resendConnectEmail: TypedDocumentNode<{ resendConnectEmail: boolean }, { companyId: string, inviteId: string }>,
  setupCompany: TypedDocumentNode<{ setupCompany: boolean }, SetupData>,
  setUserDirectDeposit: TypedDocumentNode<{ setUserDirectDeposit: boolean }, { accountNumber: string, nameOnAccount: string, routingNumber: string }>
  createCase: TypedDocumentNode<{ createCase: string }, {
    companyId: string,
    employeeId: string,
    checklistId: string,
    terminationDate: string,
    lastDay: string,
    reason: string,
    terminationType: string,
    benefitTier: BenefitTier,
    secure: boolean
  }>
  uploadBatch: TypedDocumentNode<{ uploadBatch: string }, {
    companyId: string,
    employees: EmployeeInput[],
  }>
  bulkCaseCreate: TypedDocumentNode<{ bulkCaseCreate: boolean }, {
    cases: BulkCaseCreateInput[],
  }>
  syncDataForExitCode: TypedDocumentNode<{ syncDataForExitCode: boolean }, { companyId: string, exitCode: string, birthDate: string, lastName: string, zipCode: string }>
  submitReimbursementRequest: TypedDocumentNode<{ submitReimbursementRequest: boolean }, {
    userId: string,
    whenBenefitId: string,
    files: { name: string, path: string, type: string }[],
    amount: number,
    coverageStartDate: string,
    coverageEndDate: string,
  }>
  cancelReimbursementRequest: TypedDocumentNode<{ cancelReimbursementRequest: boolean }, { userId: string, id: string, whenBenefitId: string }>
  releaseCaseBenefit: TypedDocumentNode<{ releaseCaseBenefit: boolean }, {
    companyId: string,
    caseId: string
  }>
  deleteUserDirectDeposit: TypedDocumentNode<{ deleteUserDirectDeposit: boolean }, { userId: string }>
  userSignOut: TypedDocumentNode<{ userSignOut: boolean }, { userId: string }>
  approveReimbursementRequest: TypedDocumentNode<{ approveReimbursementRequest: boolean },
    { requestId: string, requestUserId: string, requestBenefitId: string, approvedAmount: string, notes: string, status: string }>
  denyReimbursementRequest: TypedDocumentNode<{ denyReimbursementRequest: boolean },
    { requestUserId: string, requestId: string, requestBenefitId: string, denialReason: string, notes: string }>,
  setHousehold: TypedDocumentNode<{ setHousehold: boolean }, {
    uid: string, members: {
      name: string,
      dob: string,
      tobacco: boolean,
      pregnant: boolean,
      gender: string,
      relationship: string
    }[]
  }>,
  setInsuranceDetails: TypedDocumentNode<{ setInsuranceDetails: boolean }, {
    uid: string, details: {
      county: {
        name: string,
        state: string,
        zipcode: string,
        fips: string
      },
      income: number,
      type: string,
      zipcode: string,
      effectiveDate: string,
      taxFilingStatus: String,
      isNativeAmarican: boolean
    }
  }>,
  setPreferences: TypedDocumentNode<{ setPreferences: boolean }, {
    uid: string, preferences: {
      hmo?: number,
      ppo?: number,
      shortterm?: number,
      lowDeductible?: number,
      lowPremium?: number,
      comprehensiveCoverage?: number,
      networkSize?: number,
      mentalHealth?: number,
      dental?: number,
      vision?: number
    }
  }>,
  createApplication: TypedDocumentNode<{ createApplication: ApplicationResponse }, { planId: string; userId: string }>;
  submitApplication: TypedDocumentNode<{ submitApplication: Boolean }, { uid: string; applicationId: string }>;
  queueHrisSync: TypedDocumentNode<{ queueHrisSync: boolean }, { companyId: string }>
  connectFinch: TypedDocumentNode<{ hris: { finch: { connectFinch: boolean } } }, { clientCode: string, companyId: string }>
}
function getMutations(): _Mutations {
  let Mutations: any = {};
  Mutations.setHousehold = gql`
  mutation setHousehold($uid:String, $members:[HouseholdMemberInput]) {
    setHousehold(uid: $uid, members: $members)
  }`;
  Mutations.setInsuranceDetails = gql`
  mutation setInsuranceDetails($uid:String, $details:InsuranceDetailsInput) {
    setInsuranceDetails(uid: $uid, details: $details)
  }`;
  Mutations.createApplication = gql`
  mutation createApplication($planId: ID!, $userId: ID!) {
    createApplication(planId: $planId, userId: $userId) {
      applicationId
      success
      message
    }
  }
`;
  Mutations.submitApplication = gql`
mutation submitApplication($uid:String, $applicationId:String) {
  submitApplication(uid: $uid, applicationId:$applicationId)
}`;
  Mutations.setPreferences = gql`
  mutation setPreferences($uid:String, $preferences:PreferencesInput) {
    setPreferences(uid: $uid, preferences: $preferences)
  }`;
  Mutations.submitReimbursementRequest = gql`
  mutation submitReimbursementRequest($userId: String!, $whenBenefitId: String!, $files: [ReimbursementFile!]!, $amount: Float!, $coverageStartDate: String!, $coverageEndDate: String!) {
    submitReimbursementRequest(userId: $userId, whenBenefitId: $whenBenefitId, files: $files, amount: $amount,coverageStartDate: $coverageStartDate, coverageEndDate: $coverageEndDate)
  }`;
  Mutations.cancelReimbursementRequest = gql`
  mutation cancelReimbursementRequest($userId: String!, $id: String!, $whenBenefitId: String!) {
    cancelReimbursementRequest(userId: $userId, id: $id, whenBenefitId: $whenBenefitId)
  }`;
  Mutations.approveReimbursementRequest = gql`
   mutation approveReimbursementRequest($requestId: String!, $requestUserId: String!, $requestBenefitId: String!, $approvedAmount: String!, $notes:String!, $status:String!){
    approveReimbursementRequest(requestId: $requestId, requestUserId: $requestUserId, requestBenefitId: $requestBenefitId, approvedAmount: $approvedAmount, notes: $notes, status: $status)
   } `;
  Mutations.denyReimbursementRequest = gql`
   mutation denyReimbursementRequest($requestUserId: String! ,$requestId: String!, $requestBenefitId: String!, $denialReason: String!, $notes:String){
    denyReimbursementRequest(requestId: $requestId, requestUserId: $requestUserId, requestBenefitId: $requestBenefitId, denialReason: $denialReason, notes: $notes)
   } `;
  Mutations.syncDataForExitCode = gql`
  mutation syncDataForExitCode($companyId: String!, $exitCode: String!, $birthDate: String!, $lastName: String!, $zipCode: String!) {
    syncDataForExitCode(companyId: $companyId, exitCode: $exitCode, birthDate: $birthDate, lastName: $lastName, zipCode: $zipCode)
  } `;
  Mutations.contactUs = gql`mutation ContactUs($name: String, $email: String, $phone: String, $subject: String, $description: String) {
    contactUs(name: $name, email: $email, phone: $phone, subject: $subject, description: $description)
  } `
  Mutations.releaseCaseBenefit = gql`mutation releaseCaseBenefit($companyId: String!, $caseId: String! ){
    releaseCaseBenefit(companyId: $companyId,  caseId: $caseId )
  } `
  Mutations.deleteUserDirectDeposit = gql`mutation deleteUserDirectDeposit($userId: String!){
    deleteUserDirectDeposit(userId: $userId)
  } `
  Mutations.userSignOut = gql`mutation userSignOut($userId: String!){
    userSignOut(userId: $userId)
  } `
  Mutations.createCase = gql`
  mutation createCase(
    $companyId: String!
    $employeeId: String!
    $checklistId: String!
    $terminationDate: String!
    $lastDay: String
    $reason: String!
    $terminationType: String!  
    $benefitTier: BenefitTier!
    $secure: Boolean!
  ) {
    createCase(
      companyId: $companyId
      employeeId: $employeeId
      checklistId: $checklistId
      terminationDate: $terminationDate
      lastDay: $lastDay
      reason: $reason
      terminationType: $terminationType 
      benefitTier: $benefitTier
      secure: $secure
    )
  } `;
  Mutations.uploadBatch = gql`
  mutation uploadBatch($companyId: String!, $employees: [EmployeeInput]) {
    hris{
    csvUpload {
      uploadBatch(companyId: $companyId, employees: $employees)
    }
   }
  }
`;
  Mutations.bulkCaseCreate = gql`
  mutation bulkCaseCreate($cases: [BulkCaseCreateInput!]!) {
      bulkCaseCreate(bulkCaseData: $cases)
  }
`;
  Mutations.setupCompany = gql`
  mutation setupCompany($companyName: String!, $employeeCount: Int!, $country: String!, $firstName: String!, $lastName: String!, $email: String!, $title: String!, $phone: String!)
  {
    setupCompany(
      companyName: $companyName,
      employeeCount: $employeeCount,
      country: $country
      firstName: $firstName
      lastName: $lastName
      email: $email
      title: $title
      phone: $phone
    )
  } `;
  Mutations.resendConnectEmail = gql`
  mutation resendConnectEmail($companyId: String!, $inviteId: String!) {
    resendConnectEmail(companyId: $companyId, inviteId: $inviteId)
  } `;
  Mutations.connectUserToCompany = gql`
  mutation connectUserToCompany($companyId: String!, $userId: String!, $inviteId: String!) {
    connectUserToCompany(companyId: $companyId, userId: $userId, inviteId: $inviteId)
  } `;
  Mutations.syncHris = gql`
    mutation syncHRIS($companyId: String!) {
      queueHrisSync(companyId: $companyId)
  }
  `;
  Mutations.setPublicToken = gql`
    mutation setPublicToken($publicToken: String!, $companyId: String!) {
    hris {
      merge {
        setPublicToken(publicToken: $publicToken, companyId: $companyId)
      }
    }
  }
  `;
  Mutations.loadOpportunity = gql`
    mutation loadOpportunity($opportunityId: String) {
    loadOpportunity(opportunityId: $opportunityId)
  } `;

  Mutations.setUserClaims = gql`
  mutation setUserClaims($uid: String, $admin: Boolean) {
    setUserClaims(uid: $uid, admin: $admin){
      uid,
        email,
        admin
    }
  } `;
  Mutations.setUserDirectDeposit = gql`
  mutation setUserDirectDeposit($accountNumber: String!, $nameOnAccount: String!, $routingNumber: String!) {
    setUserDirectDeposit(
      accountNumber: $accountNumber,
      nameOnAccount: $nameOnAccount,
      routingNumber: $routingNumber
    )
  } 
  `;
  Mutations.queueHrisSync = gql`
  mutation queueHrisSync($companyId: String!) {
    queueHrisSync(companyId: $companyId)
  }
  `;
  Mutations.connectFinch = gql`
    mutation connectFinch($clientCode:String!, $companyId:String!) {
      hris {
        finch {
          connectFinch(clientCode: $clientCode, companyId: $companyId)
        }
      }
    }
  `;
  try {

    return Mutations;
  } catch (err: any) {
    err.message = "getMutations: " + err.message;
    throw err;
  }
}
const Mutations: _Mutations = getMutations();

export { Mutations };