/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Grid, Button, Card, Divider, Dialog } from "@mui/material";
import { useUserData } from "contexts/User/data";
import { useCallback, useEffect, useState } from "react";
import { BenefitAmounts, ReimbursementRequest, UserActivityTypes, WhenBenefit } from "services/Interfaces";
import { DataGrid } from "@mui/x-data-grid";
import { FormattedNumber } from "react-intl";
import SubmitReimbursementRequest from "components/WhenBenefit/SubmitReimbursementRequest";
import moment from "moment";
import ViewReimbursementDialog from "./ViewReimbursementDialog";
import { useAuth } from "contexts/User";
import { addDoc, collection } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { doc, getDoc } from 'firebase/firestore';
import BankAccountModal from "components/WhenBenefit/BankAccountModal";
function WhenBenefitPage() {
  const { firestore } = useFirebase();
  const { whenBenefits, reimbursementRequests } = useUserData();
  const { user } = useAuth()
  const [activeWhenBenefits, setActiveWhenBenefits] = useState<WhenBenefit[]>([]);
  const [inactiveWhenBenefits, setInactiveWhenBenefits] = useState<WhenBenefit[]>([]);
  // const [approvedReimbursementRequests, setApprovedReimbursementRequests] = useState(0);
  // const [pendingReimbursementRequests, setPendingReimbursementRequests] = useState(0);
  const [whenBenefit, setWhenBenefit] = useState<WhenBenefit | undefined>(undefined);
  const [viewReimbursement, setViewReimbursement] = useState<ReimbursementRequest | undefined>(undefined);
  const [openViewReimbursement, setOpenViewReimbursement] = useState(false)
  const [benefitsAmount, setBenefitsAmount] = useState<BenefitAmounts>({})
  const [hasBankAccount, setHasBankAccount] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const addActivity = useCallback(async () => {
    try {
      const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
      await addDoc(userActivityCollectionRef, {
        activity: UserActivityTypes.VIEWEDWHENBENEFIT,
        type: "user",
        userId: user?.uid,
        Timestamp: new Date(),
      });
    } catch (error) {
      console.log("error", error)
    }
  }, [firestore, user?.uid]);
  useEffect(() => {
    if (whenBenefits && whenBenefits?.size > 0) {
      const sorted = whenBenefits.docs.sort((a, b) => {
        const aDate = a.get("createdAt");
        const bDate = b.get("createdAt");
        if (aDate > bDate) return -1;
        if (aDate < bDate) return 1;
        return 0;
      });
      const active = sorted.filter((doc) => doc.get("status") === "new" || doc.get("status") === "active").map((doc) => doc.data());
      const inactive = sorted.filter((doc) => doc.get("status") !== "active").map((doc) => doc.data());
      let amounts: BenefitAmounts = {};
      active.forEach((item) => {
        const currentReimbursements = reimbursementRequests?.docs.filter((data) => data.data().whenBenefitId === item.id);
        const approved = currentReimbursements?.filter((doc) => doc.get("status") === "Approved" || doc.get("status") === "Partially Approved").map((doc) => doc.data()).reduce((acc, data) => {
          return acc + (data?.approvedAmount || 0)
        }, 0);
        const pending = currentReimbursements?.filter((doc) => doc.get("status") === "Pending").map((doc) => doc.data()).reduce((acc, data) => {
          return acc + data.amount
        }, 0);
        amounts = {
          ...amounts,
          [item.id]: {
            approved: approved || 0,
            pending: pending || 0,
            remaining: Number(item?.maxBenefit) - Number(approved) - Number(pending),
            total: item?.maxBenefit || 0,
          }
        };
      });
      setBenefitsAmount(amounts);
      setActiveWhenBenefits(active);
      setInactiveWhenBenefits(inactive);
    }
    try {
      addActivity()
    } catch (error) {
      console.log("error")
    }
  }, [addActivity, whenBenefits, reimbursementRequests]);
  // useEffect(() => {
  //   console.log("reimbursementRequests", reimbursementRequests?.docs.map((doc) => doc.data()))
  //   const approved = reimbursementRequests?.docs?.filter((doc) => doc.get("status") === "Approved" || doc.get("status") === "Partially Approved").map((doc) => doc.data()).reduce((acc, data) => {
  //     return acc + (data?.approvedAmount || 0)
  //   }, 0);
  //   const pending = reimbursementRequests?.docs?.filter((doc) => doc.get("status") === "Pending").map((doc) => doc.data()).reduce((acc, data) => {
  //     return acc + data.amount
  //   }, 0);
  //   console.log('approved pending', approved, pending);
  //   setApprovedReimbursementRequests(Number(approved));
  //   setPendingReimbursementRequests(Number(pending));
  // }, [reimbursementRequests]);
  useEffect(() => {
    const checkBankAccount = async () => {
      if (user) {
        try {
          const docRef = doc(firestore, 'users', user.uid, 'settings', 'directDeposit');
          const docSnapshot = await getDoc(docRef);
          setHasBankAccount(docSnapshot.exists());
        } catch (error) {
          console.error('Error checking directDeposit:', error);
          setHasBankAccount(false);
        }
      }
    };
    checkBankAccount();
  }, [user, firestore]);
  const reimbursementRequestColumns = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 150,
      valueFormatter: (params: any) => {
        return moment(params?.value).format('MM/DD/YYYY')
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "amount",
      headerName: "Amount requested ",
      width: 200,
      renderCell: (params: any) => {
        return <FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={params.value} />
      }
    },
    {
      field: "approvedAmount",
      headerName: "Amount approved",
      width: 200,
      renderCell: (params: any) => {
        if (params?.row?.status === 'Approved') {
          return <FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={params?.row?.amount} />
        }
        if (params?.row?.status === 'Pending' || params?.row?.status === 'Additional Information Requested') {
          return <></>
        }
        if (params?.row?.status === 'Denied' || params?.row?.status === 'Cancelled') {
          return <FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={0} />
        }
        if (params?.row?.status === 'Partially Approved') {
          if (params?.row?.amount > params.value) {
            return <FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={params.value} />
          }
          else {
            return <></>
          }
        }
      }
    },
    {
      field: "coverageStartDate",
      headerName: "Coverage period start date",
      width: 220,
      valueFormatter: (params: any) => {
        return moment(params?.value).format('MM/DD/YYYY')
      }
    },
    {
      field: "coverageEndDate",
      headerName: "Coverage period end date",
      width: 220,
      valueFormatter: (params: any) => {
        return moment(params?.value).format('MM/DD/YYYY')
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (<Button variant="contained" color="primary" onClick={() => {
          setOpenViewReimbursement(true)
          setViewReimbursement(params?.row)
        }}>View</Button>)
      }
    }
  ];
  return (
    <Box>
      <Box sx={{
        py: 4
      }}>
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12} ms={4}>
              <Card sx={{
                height: "100%"
              }}>
                <Box>
                  <Typography variant="h2" sx={{ p: 2 }}>How it works</Typography>
                  <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
                  <Typography variant="body1" sx={{ p: 2, fontSize: { xl: "1.3rem", lg: "1rem", sm: "0.9rem" } }}>Use your When Benefit to reimburse yourself for health insurance premiums. Enroll in health insurance, pay your premium, and submit a Reimbursement Request with proof of payment.</Typography>
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} ms={8} sx={{}}>
              <Card>
                <Box>
                  <Typography variant="h2" sx={{ p: 2 }}>Active When Benefit</Typography>
                  <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
                  {activeWhenBenefits?.length > 0 && activeWhenBenefits.map((whenBenefit) => {
                    return (
                      <Card key={whenBenefit.id} color="primary" sx={{
                        m: { xl: 4, xs: 2 },
                        p: { xl: 4, xs: 2 },
                        backgroundColor: "primary.main",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                        <Grid container>
                          <Grid item xs={12} sm={3}>
                            <Box sx={{
                              display: "flex",
                              flexDirection: { xs: "row", sm: "column" },
                              gap: 2,
                              p: { xs: 1.5, sm: 0 }
                            }}>
                              <Typography color={"white.main"} sx={{ fontSize: { xl: "1rem", lg: "0.9rem", sm: "0.8rem", xs: "0.74rem" } }}>Company</Typography>
                              <Typography color={"white.main"} variant="h3" sx={{ fontSize: { xl: "1.3rem", lg: "1rem", sm: "0.9rem", xs: "0.9rem" } }}>{whenBenefit.company}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box sx={{
                              display: "flex",
                              flexDirection: { xs: "row", sm: "column" },
                              gap: 1,
                              p: { xs: 1.5, sm: 0 }
                            }}>
                              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Typography color={"white.main"} sx={{ fontSize: { xl: "1rem", lg: "0.9rem", sm: "0.8rem", xs: "0.74rem" } }}>Total Benefit</Typography>
                                <Typography color={"white.main"} variant="h4" sx={{ fontSize: { xl: "1.3rem", lg: "1rem", sm: "0.9rem", xs: "0.9rem" } }}><FormattedNumber style={`currency`} maximumFractionDigits={0} currency="usd" value={benefitsAmount[whenBenefit.id]?.total} /></Typography>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Typography color={"white.main"} sx={{ fontSize: { xl: "1rem", lg: "0.9rem", sm: "0.8rem", xs: "0.74rem" } }}>Pending</Typography>
                                <Typography color={"white.main"} variant="h4" sx={{ fontSize: { xl: "1.3rem", lg: "1rem", sm: "0.9rem", xs: "0.9rem" } }}><FormattedNumber style={`currency`} maximumFractionDigits={0} currency="usd" value={benefitsAmount[whenBenefit.id]?.pending} /></Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box sx={{
                              display: "flex",
                              flexDirection: { xs: "row", sm: "column" },
                              gap: 1,
                              p: { xs: 1.5, sm: 0 }
                            }}>
                              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Typography color={"white.main"} sx={{ fontSize: { xl: "1rem", lg: "0.9rem", sm: "0.8rem", xs: "0.74rem" } }}>Remaining</Typography>
                                <Typography color={"white.main"} variant="h4" sx={{ fontSize: { xl: "1.3rem", lg: "1rem", sm: "0.9rem", xs: "0.9rem" } }}><FormattedNumber style={`currency`} maximumFractionDigits={0} currency="usd" value={benefitsAmount[whenBenefit.id]?.remaining} /></Typography>
                              </Box>
                              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <Typography color={"white.main"} sx={{ fontSize: { xl: "1rem", lg: "0.9rem", sm: "0.8rem", xs: "0.74rem" } }}>Used</Typography>
                                <Typography color={"white.main"} variant="h4" sx={{ fontSize: { xl: "1.3rem", lg: "1rem", sm: "0.9rem", xs: "0.9rem" } }}><FormattedNumber style={`currency`} maximumFractionDigits={0} currency="usd" value={benefitsAmount[whenBenefit.id]?.approved} /></Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Box>
                              <Button data-testid="ReimbursementRequestButton" variant="contained" color="secondary" onClick={() => { setWhenBenefit(whenBenefit) }} sx={{ padding: 1.5, fontSize: { xl: "0.8rem", lg: "0.7rem", sm: "0.65rem", xs: "0.7rem" }, m: { xs: 1, sm: 0 } }}>Submit Reimbursement Request</Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>
                    )
                  })}
                  {activeWhenBenefits?.length === 0 && <Typography sx={{ p: 2 }}>No active When Benefits</Typography>}
                </Box>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <DataGrid
                  slotProps={{ filterPanel: { sx: { maxWidth: "90vw" } } }}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 25, page: 0 },
                    },
                  }}
                  sx={{
                    '& .MuiTablePagination-root': {
                      '& .MuiTablePagination-selectLabel': {
                        display: { xs: "flex" },
                        fontSize: { xs: "0.6rem", sm: "0.875rem" },
                      },
                      '& .MuiTablePagination-input': {
                        display: { xs: "flex" },
                        fontSize: { xs: "0.6rem", sm: "0.875rem" },
                      },
                      '& .MuiTablePagination-select': {
                        marginX: { xs: 0, sm: 1 }
                      },
                      '& .MuiTablePagination-displayedRows': {
                        fontSize: { xs: "0.6rem", sm: "0.875rem" },
                      },
                      '& .MuiTablePagination-toolbar': {
                        padding: 0,
                        margin: 0
                      },
                      '& .MuiTablePagination-actions': {
                        margin: { xs: 0, sm: 3 }
                      },
                    }
                  }}
                  slots={{
                    toolbar: () => {
                      return (
                        <Box>
                          <Typography variant="h2" sx={{ p: 2 }}>Request History</Typography>
                          <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
                        </Box>
                      )
                    }
                  }}
                  rows={reimbursementRequests?.docs.map((doc) => doc.data()) || []}
                  columns={reimbursementRequestColumns}
                  autoHeight
                />
              </Card>
              {(openViewReimbursement && viewReimbursement) && (<ViewReimbursementDialog openViewReimbursement={openViewReimbursement} setOpenViewReimbursement={setOpenViewReimbursement} reimbursement={viewReimbursement} />)}
            </Grid>
            <Grid item xs={12}
              sx={{ visibility: "hidden" }}
            >
              <Card>
                <DataGrid
                  slotProps={{ filterPanel: { sx: { maxWidth: "90vw" } } }}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 25, page: 0 },
                    },
                  }}
                  sx={{
                    '& .MuiTablePagination-root': {
                      '& .MuiTablePagination-selectLabel': {
                        display: { xs: "flex" },
                        fontSize: { xs: "0.6rem", sm: "0.875rem" },
                      },
                      '& .MuiTablePagination-input': {
                        display: { xs: "flex" },
                        fontSize: { xs: "0.6rem", sm: "0.875rem" },
                      },
                      '& .MuiTablePagination-select': {
                        marginX: { xs: 0, sm: 1 }
                      },
                      '& .MuiTablePagination-displayedRows': {
                        fontSize: { xs: "0.6rem", sm: "0.875rem" },
                      },
                      '& .MuiTablePagination-toolbar': {
                        padding: 0,
                        margin: 0
                      },
                      '& .MuiTablePagination-actions': {
                        margin: { xs: 0, sm: 3 }
                      },
                    }
                  }}
                  slots={{
                    toolbar: () => {
                      return (
                        <Box>
                          <Typography variant="h2" sx={{ p: 2 }}>When Benefit History</Typography>
                          <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
                        </Box>
                      )
                    }
                  }}
                  rows={inactiveWhenBenefits}
                  columns={[]}
                  autoHeight
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={!!whenBenefit}
        onClose={() => { setWhenBenefit(undefined) }}
      >
        <Box sx={{
          p: 4
        }}
          data-testid="submitReimbursementRequestModal">
          <SubmitReimbursementRequest hasBankAccount={hasBankAccount} whenBenefit={whenBenefit!} remaining={benefitsAmount[whenBenefit?.id || ""]?.remaining || 0} onCancel={() => { setWhenBenefit(undefined) }} onSave={() => {
            setWhenBenefit(undefined)
            if (!hasBankAccount)
              setModalOpen(true)
          }} />
        </Box>
      </Dialog>
      <BankAccountModal headerText="Add your bank account to get paid back faster"
        bodyText=" We've received your request and are reviewing it for reimbursement. In order to receive your reimbursement funds more quickly, add your bank account so When can pay you electronically."
        submitButtonText="Save" cancelButtonText="Skip for now" open={modalOpen} onClose={() => { setModalOpen(false) }} />
    </Box >
  );
}
export default WhenBenefitPage;