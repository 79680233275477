import {
  gql,
  DocumentNode,
  TypedDocumentNode
} from "@apollo/client";
import { CsvData, HrisConnection } from "services/Interfaces";
interface _Queries {
  lookupEmployee: DocumentNode
  SEARCH_PLANS: DocumentNode
  recommendPlans: DocumentNode
  INDIVIDUAL_RATE: DocumentNode
  GET_GEOGRAPHY: DocumentNode
  listUsers: DocumentNode
  getRates: DocumentNode
  subsidyCheck: TypedDocumentNode<{subsidyCheck:{federalSubsidyAmount:number}},{uid:string}>,
  generateEnrollmentUrl: DocumentNode
  aiRecommendation: DocumentNode
  reScorePlans: DocumentNode
  adminGetCase: TypedDocumentNode<{ adminGetCase: { caseId: string, expiration: string, companyId: string } }, { caseId: string, companyId: string, reason: string }>
  getLinkToken: TypedDocumentNode<{ hris: { merge: { getLinkToken: { token: string } } } }, { companyId: string, companyName: string, emailAddress: string, integrationMethodId: string }>,
  hrisConnectionDetails: TypedDocumentNode<{ hrisConnectionDetails: HrisConnection }, { companyId?: string, adminSearch?: boolean }>
  confirmExitCodeIdentity: TypedDocumentNode<{ confirmExitCodeIdentity: boolean }, { companyId: string, exitCode?: string, lastName: string, birthDate: string, zipCode?: string, workEmail?: string, terminationDate?: string }>,
  getSearchToken: TypedDocumentNode<{ getSearchToken: string }, { companyId?: string, adminSearch?: boolean }>
  estimateImpact: TypedDocumentNode<{ hris: { csvUpload: { estimateImpact: { new: number, missing: number, total: number, existing:number } } } }, { companyId: string, ids: Array<string> }>
  exitCodeInfo: TypedDocumentNode<{ exitCodeInfo: { firstName: string, documents: number, whenBenefit: number } }, { companyId: string, exitCode: string }>
  caseEstimateImpact: TypedDocumentNode<{ caseEstimateImpact: { casesToBeCreated: number, membersNotFound: number, casesToBeUpdated: number, caseExistsNoUpdates: number } }, { csvData: CsvData[] }>
  getUserBankInfo: TypedDocumentNode<{ getUserBankInfo: { accountNumber: string, routingNumber: string, nameOnAccount: string } }, { userId: string }>
}

function getQueries(): _Queries {
  let Queries: any = {
    getUserBankInfo: gql`
    query getUserBankInfo($userId: String!) {
      getUserBankInfo(userId: $userId) {
        accountNumber
        routingNumber
        nameOnAccount
      }
    }`,
    exitCodeInfo: gql`
      query exitCodeInfo($companyId: String!, $exitCode: String!) {
      exitCodeInfo(companyId: $companyId, exitCode: $exitCode) {
        firstName
        documents
        whenBenefit
        }
      }
    `,
    adminGetCase: gql`
    query adminGetCase($caseId: String!, $companyId: String!, $reason: HIPAAViewReasons) {
      adminGetCase(caseId: $caseId, companyId: $companyId, reason: $reason) {
        caseId
        companyId
        expiration
      }
    }
    `,
    estimateImpact: gql` query Hris($companyId: String, $ids: [String]) {
      hris {
        csvUpload {
          estimateImpact(companyId: $companyId, ids: $ids) {
            missing
            new
            total
            existing
          }
        }
      }
    }`,
    caseEstimateImpact: gql`
     query caseEstimateImpact($csvData: [CsvInput!]!) {
      caseEstimateImpact(csvData: $csvData) {
        casesToBeCreated
        membersNotFound
        casesToBeUpdated
        caseExistsNoUpdates
      }
    }`,
    getSearchToken: gql`
      query getSearchToken($companyId: String, $adminSearch: Boolean) {
        getSearchToken(companyId: $companyId, adminSearch:$adminSearch) 
      }
    `,
    hrisConnectionDetails: gql`
    query hrisConnectionDetails ($connectionId: String, $companyId: String) {
      hrisConnectionDetails(connectionId: $connectionId, companyId: $companyId) {
        name
        logo
        squareLogo
        status
        lastImport
        createdOn
      }
    }
    `,
    confirmExitCodeIdentity: gql`
      query confirmExitCodeIdentity($companyId: String!, $exitCode: String, $lastName: String!, $birthDate: String!, $zipCode: String, $workEmail: String, $terminationDate: String) {
        confirmExitCodeIdentity(companyId: $companyId, exitCode: $exitCode, lastName: $lastName, birthDate: $birthDate, zipCode: $zipCode, workEmail: $workEmail, terminationDate: $terminationDate)
      }
    `,
    reScorePlans: gql`
    query reScorePlans($effectiveDate: String, $uid:String) {
      reScorePlans(effectiveDate: $effectiveDate, uid:$uid) {
        total
      }
    }`,
    aiRecommendation: gql`
    query aiRecommendation($effectiveDate: String, $planId: String) {
      aiRecommendation(effectiveDate: $effectiveDate, planId: $planId) {
        aiScore
        pros
        cons
        headline
        explanation
      }
    }`,
    generateEnrollmentUrl: gql`
    query generateEnrollmentUrl($effectiveDate:String, $planId:String, $carrierId:String){
      generateEnrollmentUrl(effectiveDate:$effectiveDate, planId:$planId, carrierId:$carrierId){
        redirectUrl
      }
    }
    `,
    getLinkToken: gql` query GetLinkToken($companyId: String!, $companyName: String!, $emailAddress: String!, $integrationMethodId: String!) {
      hris {
        merge {
          getLinkToken(companyId: $companyId, companyName: $companyName, emailAddress: $emailAddress, integrationMethodId:  $integrationMethodId) {
            token
          }
        }
      }
    }`,
    getRates: gql`
    query getRates($effectiveDate: String, $uid:String){
      getRates(effectiveDate: $effectiveDate, uid: $uid){
        total
      }
    }
    `,
    subsidyCheck: gql`
    query SubsidyCheck($uid: String!) {
      subsidyCheck(uid: $uid) {
        federalSubsidyAmount
      }
    }
  `,
    lookupEmployee: gql`
    query lookupEmployee($company: String, $lastName: String, $dateOfBirth:String, $email: String){
      lookupEmployee(company:$company, lastName:$lastName, email:$email, dateOfBirth:$dateOfBirth ){
        firstName
        lastName
        employeeId
        benefit
      }
    }
    `,
    recommendPlans: gql`
    query recommendPlans($filters: String) {
      recommendPlans(filters: $filters) {
        plans{
          lowestDeductible{
            premium
            name
            provider
            id
            source
            type
            maxOutOfPocket
            deductible
            metal
            rating
            market
            diseasePrograms,
            benefits {
              name
              covered
              inNetwork
              outOfNetwork
            }
            benefitUrl,
            formularyUrl,
            brochureUrl,
            networkUrl
          }
          lowestPremium{
            premium
            name
            provider
            id
            source
            type
            maxOutOfPocket
            deductible
            metal
            rating
            market
            diseasePrograms,
            benefits {
              name
              covered
              inNetwork
              outOfNetwork
            }
            benefitUrl,
            formularyUrl,
            brochureUrl,
            networkUrl
          }
          lowestOutOfPocket{
            premium
            name
            provider
            id
            source
            type
            maxOutOfPocket
            deductible
            metal
            rating
            market
            diseasePrograms,
            benefits {
              name
              covered
              inNetwork
              outOfNetwork
            }
            benefitUrl,
            formularyUrl,
            brochureUrl,
            networkUrl
          }
        }
        total
      }
    }
    `
  };

  Queries.listUsers = gql`
    query listUsers($filters: String, $page: Int, $limit: Int) {
      listUsers(filters: $filters, page: $page, limit: $limit) {
        users{
          uid
          email
          admin
        }
      }
    }
  `;
  try {
    Queries.GET_GEOGRAPHY = gql`
    query geographyByZip($zipcode: String) {
      geographyByZip(zipcode: $zipcode) {
        zipcode
        name
        fips
        state
      }
    }
  `;

  } catch (err) {
    console.error("GET_GEOGRAPHY error", err);
  }


  try {
    Queries.SEARCH_PLANS = gql`
    query SearchPlansQuery($filters: String, $page: Int) {
    searchPlans(filters: $filters, page: $page) {
      plans{
        premium
        name
        provider
        id
        source
        type
        maxOutOfPocket
        deductible
        metal
        rating
        market
        diseasePrograms,
        benefits {
          name
          covered
          inNetwork
          outOfNetwork
        }
        benefitUrl,
        formularyUrl,
        brochureUrl,
        networkUrl
      }
      total
    }
  }
  `;
  } catch (err) {
    console.error("SEARCH_PLANS error", err);
  }
  return Queries;
}

const Queries: _Queries = getQueries();
export { Queries };