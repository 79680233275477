/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Button, Grid, Card, CardContent, CardActions, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { ServiceMarketPlaceType } from "services/Interfaces";
import { CheckBox, CheckBoxOutlineBlank, Phone} from "@mui/icons-material";
import { useDocument } from "react-firebase-hooks/firestore";
import { query, collection, getDocs } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { useUserData } from "contexts/User/data";

// TODO: Extract into helper file
function formatPhoneNumber(phoneNumber: string): string {
  const digits = phoneNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  }
  return phoneNumber;
}

export function ServiceCard({ service }: { service: ServiceMarketPlaceType }) {
  return (
    <Card sx={{
      width: {xs:"100%"},
      maxWidth:"400px",
      height: "500px",
      backgroundColor: service.tileBackgroundColor,
      backgroundImage: service.tilesTulipsPrint ? `url("/images/tulip.svg")` : undefined,
      backgroundRepeat: "repeat-x",
      backgroundPosition: "top left",
      display: "flex",
      flexDirection: "column",

    }}>
      <Box sx={{
        backgroundImage: service.tilesTulipsPrint ? `url("/images/tulip.svg")` : undefined,
        backgroundRepeat: "repeat-x",
        backgroundPosition: "bottom left",
        height: "100%",
        padding: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}>
        <CardContent sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}>
          <Typography variant="h2" textAlign="center" sx={{
            pt: 2,
            color: service.fontColor,
            fontWeight: 600,
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {service.tileHeader}
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{
            pt: 2,
            color: service.fontColor,
            fontWeight: 400,
            display: '-webkit-box',
            WebkitLineClamp: 7,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            {service.tileBodyText}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2 }}>
          {service.CTAButton && service.CTAButtonURL ? (
            <Button
              variant="contained"
              color="white"
              sx={{ mb: 1 }}
              href={service.CTAButtonURL}
              target="_blank"
            >
              {service.CTAButtonText}
            </Button>
          ) : null}
          {service.phoneButton && service.phoneNumber && (
            <Button
              variant="contained"
              color="white"
              sx={{ mb: 1 }}
              component="a"
              href={`tel:${service.phoneNumber}`}
            >
              <Phone /> {formatPhoneNumber(service.phoneNumber)}
            </Button>
          )}
        </CardActions>
        {service.redeemCode && <Typography sx={{ color: service.fontColor }} variant="body1">Redeem Code: {service.redeemCode}</Typography>}
        {service.footer && (
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            pt: 2,
          }}>
            <Typography fontWeight={600} sx={{color: service.fontColor }} >{service.footer}</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
}

function Services() {
  const { firestore } = useFirebase();
  const [services, setServices] = useState<ServiceMarketPlaceType[] | undefined>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const { userDoc } = useUserData();

  const [ userData ] = useDocument(userDoc);

  useEffect(() => {
    const fetchServices = async () => {
      const globalServicesQuery = query(
        collection(firestore, "serviceMarketplace")
      );
      const globalServicesSnapshot = await getDocs(globalServicesQuery);
      const globalServicesData = globalServicesSnapshot.docs.map((doc) =>
        doc.data()
      ) as ServiceMarketPlaceType[];

      const servicesOfferedRef = collection(
        firestore,
        `users/${userData?.data()?.uid}/formerEmployments/${userData?.data()?.syncEmployeeId
        }/servicesOffered`
      );

      const servicesOfferedSnapshot = await getDocs(
        servicesOfferedRef
      );

      const companyServicesData = servicesOfferedSnapshot.docs.map((doc) => doc.data()) as ServiceMarketPlaceType[];

      const combinedServices = [
        ...globalServicesData,
        ...companyServicesData,
      ];

      return combinedServices;
    };

    fetchServices();

    const fetchData = async () => {
      const combinedServices = await fetchServices();
      setServices(combinedServices);
    };

    fetchData();
  }, [firestore, userData]);

  useEffect(() => {
    if (services && services.length > 0) {
      console.log("Populating categories...");
      const categorySet = new Set<string>();
      services.forEach((service) => {
        if (service.serviceCategory) {
          if (Array.isArray(service.serviceCategory)) {
            service.serviceCategory.forEach((cat) => categorySet.add(cat));
          } else {
            categorySet.add(service.serviceCategory);
          }
        }
      });
      setCategories(Array.from(categorySet));
    }
  }, [services]);

  const toggleCategory = (categoryIn: string) => {
    if (selectedCategories.includes(categoryIn)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryIn));
    } else {
      setSelectedCategories([...selectedCategories, categoryIn]);
    }
  };

  const filteredServices = services?.filter((service) =>
    selectedCategories.length === 0
      ? true // If no categories are selected, show all services
      : selectedCategories.some((cat) =>
        Array.isArray(service.serviceCategory)
          ? service.serviceCategory.includes(cat)
          : service.serviceCategory === cat
      )
  );

  // Move services with tilesVisibility to the beginning of the filtered list
  const sortedServices = filteredServices?.sort((a, b) => {
    if (a.tilesVisibility && !b.tilesVisibility) {
      return -1; // Service a has tilesVisibility, should be placed first
    } else if (!a.tilesVisibility && b.tilesVisibility) {
      return 1; // Service b has tilesVisibility, should be placed first
    }
    return 0; // Both services have or don't have tilesVisibility, maintain order
  });

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <Container sx={{ py: 4, display: "flex", flexDirection: "row", gap: 2 , flexWrap: {
          xs: 'wrap',
          md: 'nowrap',
            },}}>
        <Box
          sx={{
            maxWidth: "300px",
            width: "300px",
            flexGrow: 1,
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Categories
            </Typography>
            <List>
              {categories &&
                categories.map((categoryIn, index) => (
                  <ListItem key={index} disablePadding>
                    {selectedCategories.includes(categoryIn) ? (
                      <CheckBox />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                    <ListItemButton onClick={() => toggleCategory(categoryIn)}>
                      <ListItemText primary={categoryIn} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Services
          </Typography>
          <Grid container spacing={2}>
            {sortedServices ? (
              sortedServices?.map((service) =>
                service.isActive ? (
                  <Grid item xs={12} md={6} key={service.id} sx={{ minWidth: 300 }}>
                    <ServiceCard service={service} />
                  </Grid>
                ) : null
              )
            ) : (
              <Grid item>
                <h3> Currently no services are available.</h3>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
export default Services;