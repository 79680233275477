/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import { Divider, FormControl, IconButton, Typography } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useAuth } from 'contexts/User';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { UserDataConverter } from 'services/Firestore/User';
import { Edit } from '@mui/icons-material';
import { useNotifications } from 'contexts/Notification';
import { UserActivityTypes } from 'services/Interfaces';
import ConfirmSubmissionDialog from './ConfirmSubmissionDialog';
import { useFirebase } from 'contexts/Firebase';

interface Field {
  field: string,
  label: string;
  value: string;
}
interface PersonalInfo {
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
}

function PersonalInformation() {
  const { firestore } = useFirebase();
  const { userData } = useAuth();

  const initialFields: Field[] = [
    { field: "first_name", label: 'First Name', value: '' },
    { field: "last_name", label: 'Last Name', value: '' },
    { field: "date_of_birth", label: 'Date of Birth', value: '' },
    { field: "gender", label: 'Gender', value: '' }
  ];
  const [fields, setFields] = useState<Field[]>(initialFields);
  const [isOpen, setIsOpen] = useState<boolean>(false); // triggers for edit modal
  const [validationObject, setValidationObject] = useState<any>({});
  const [openConfirmSubmission, setOpenConfirmSubmission] = useState(false)
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
    firstName: "",
    lastName: "",
    dob: "",
    gender: ""
  });
  const { notifications } = useNotifications() // for showing notifications
  useEffect(() => {
    setFields(
      [
        { field: "first_name", label: 'First Name', value: userData?.data()?.name?.first || "" },
        { field: "last_name", label: 'Last Name', value: userData?.data()?.name?.last || "" },
        { field: "date_of_birth", label: 'Date of Birth', value: userData?.data()?.dob || "" },
        { field: "gender", label: 'Gender', value: userData?.data()?.gender || "" }
      ]
    )
  }, [userData])

  // Define the gender options
  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'other', label: 'Other' },
  ];
  const handleEdit = () => {
    // Initialize edited fields with current values
    setPersonalInfo({
      firstName: userData?.data()?.name?.first || "",
      lastName: userData?.data()?.name?.last || "",
      dob: userData?.data()?.dob || "",
      gender: userData?.data()?.gender || "",
    })
    setValidationObject({})
    setIsOpen(true);
  };

  const handleSave = async () => {
    if (Object.keys(validationObject).length === 0) {
      // update personal information of user
      await setDoc(doc(firestore, `users/${userData?.id}`).withConverter(UserDataConverter), {
        name: {
          first: personalInfo.firstName,
          last: personalInfo.lastName,
          lowercase: `${personalInfo.firstName} ${personalInfo.lastName}`.toLowerCase(),
          display: `${personalInfo.firstName} ${personalInfo.lastName}`
        },
        dob: personalInfo.dob,
        gender: personalInfo.gender
      }, { merge: true })
      const userActivityCollectionRef = collection(firestore, `users/${userData?.id}/activity`);
      await addDoc(userActivityCollectionRef, {
        activity: UserActivityTypes.UPDATEDSETTINGS,
        type: "user",
        userId: userData?.id,
        Timestamp: new Date(),
      });
      setIsOpen(false);
      setOpenConfirmSubmission(false)
      setValidationObject({})
      notifications.success("Personal information edited successfully")
    }
  };
  // close the edit modal
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleDateChange = (date: string) => {
    setPersonalInfo({ ...personalInfo, dob: date });
    delete validationObject['dob']
    setValidationObject(validationObject)
  }

  const handleChangeForm = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalInfo({ ...personalInfo, [name]: event.target.value });
    if (!event.target.value) {
      setValidationObject({
        ...validationObject,
        [name]: `${name?.charAt(0).toUpperCase()}${name?.slice(1)} cannot be empty`
      })
    } else {
      delete validationObject[name]
      setValidationObject(validationObject)
    }
  };

  const handleConfirmation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userDataName = userData?.data()?.name;
    const userDataDob = userData?.data()?.dob;
    const userDataGender = userData?.data()?.gender;

    if (
      (personalInfo.firstName !== (userDataName?.first || "")) ||
      (personalInfo.lastName !== (userDataName?.last || "")) ||
      (personalInfo.dob !== (userDataDob || "")) ||
      (personalInfo.gender !== (userDataGender || ""))
    ) {
      console.log("save personal info", personalInfo)
      const validation: any = {};
      Object.keys(personalInfo).forEach(function (key) {
        if (!personalInfo[key as keyof PersonalInfo]) {
          validation[key] = `${key?.charAt(0).toUpperCase()}${key?.slice(1)} cannot  be empty`;
        }
      });
      if (Object.keys(validation).length > 0) {
        console.log("validation", validation)
        setValidationObject(validation)
        return;
      } else {
        setValidationObject({});
        console.log("confirmation screen")
        setOpenConfirmSubmission(true);
      }
    } else {
      console.log("cancel")
      handleCancel();
    }
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 4 } }}>
        <Typography variant="h3" color="primary" fontWeight={600}>Personal Information<IconButton color='primary' sx={{ mb: 1 }} onClick={handleEdit} ><Edit /></IconButton></Typography>
        <Divider />
        <Grid container spacing={2} sx={{ py: 2, wordBreak: "break-word" }}>
          {fields.map((field) => (
            <Grid item xs={12} key={field.label}>
              <Typography sx={{ textTransform: 'capitalize' }} variant='body1' ><strong>{field.label}:</strong> {field.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Dialog open={isOpen} onClose={handleCancel} sx={{ "& .MuiPaper-root": { width: { sm: "50%", xs: "70%" } } }} >
        <DialogContent sx={{ p: { xs: 2, sm: 4 } }}>
          <Box component='form'
            onSubmit={handleConfirmation}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { sm: 3, xs: 2 }
            }}>
            <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' } }}>Edit Personal Information</Typography>
            <FormControl>
              <TextField
                label={"First Name"}
                variant="outlined"
                type={'text'}
                error={Boolean(validationObject['firstName'])}
                value={personalInfo?.firstName}
                onChange={handleChangeForm("firstName")}
                fullWidth
              />
              {validationObject['firstName'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['firstName']}</Typography>}
            </FormControl>
            <FormControl>
              <TextField
                label={"Last Name"}
                variant="outlined"
                type={'text'}
                error={Boolean(validationObject['lastName'])}
                value={personalInfo?.lastName}
                onChange={handleChangeForm("lastName")}
                fullWidth
              />
              {validationObject['lastName'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['lastName']}</Typography>}
            </FormControl>
            <FormControl>
              <DatePicker
                label={'Date of Birth'}
                value={personalInfo?.dob ? moment(personalInfo?.dob) : null}
                maxDate={moment()}
                onError={(newError) => {
                  setValidationObject({
                    ...validationObject,
                    dob: newError ? "Please provide a date with the following format MM/DD/YYYY" : ""
                  })
                }}
                onChange={(date) => {
                  handleDateChange(moment(date).format('MM/DD/YYYY'))
                }}
              ></DatePicker>
              {validationObject['dob'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['dob']}</Typography>}
            </FormControl>
            <FormControl>
              <TextField
                select
                label="Gender"
                value={personalInfo?.gender}
                onChange={handleChangeForm("gender")}
                error={Boolean(validationObject['gender'])}
                fullWidth
              >
                {genderOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {validationObject['gender'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['gender']}</Typography>}
            </FormControl>

            <Button type='submit' variant="contained" color="primary" >Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Box>
        </DialogContent>
      </Dialog>
      {openConfirmSubmission && <ConfirmSubmissionDialog confirmSubmit={handleSave} openConfirmSubmission={openConfirmSubmission} setOpenConfirmSubmission={setOpenConfirmSubmission} />}
    </>);
}
export default PersonalInformation;