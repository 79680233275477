/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { Card, CardContent, Divider, IconButton, Typography } from '@mui/material';
import { AccountBalanceRounded, DeleteForeverRounded } from '@mui/icons-material';
import { useNotifications } from 'contexts/Notification';
import { Mutations, useMutation } from 'services/apollo';
import { useDocument } from 'react-firebase-hooks/firestore';
import { useAuth } from 'contexts/User';
import { addDoc, collection, doc } from 'firebase/firestore';
import { UserActivityTypes } from 'services/Interfaces';
import { useFirebase } from 'contexts/Firebase';
import BankAccountModal from 'components/WhenBenefit/BankAccountModal';

interface DepositData {
  last4?: string;
}

function DirectDeposit() {
  const { firestore } = useFirebase();
  const [isOpen, setIsOpen] = useState<boolean>(false); // triggers for edit modal
  const [deleteUserDirectDeposit] = useMutation(Mutations.deleteUserDirectDeposit);
  const [directDepositData, setDirectDepositData] = useState<DepositData | undefined>()

  const { notifications } = useNotifications() // for showing notifications
  const { user } = useAuth();

  const [accountData, ,] = useDocument(
    user ? doc(firestore, 'users', user?.uid!, 'settings', 'directDeposit') : null,
  )
  useEffect(() => {
    setDirectDepositData(accountData?.data());
  }, [accountData])

  const handleEdit = () => {
    setIsOpen(true);
  };
  // add bank account

  // delete bank account
  const handleDelete = async () => {
    try {
      await deleteUserDirectDeposit({
        variables: {
          userId: user?.uid!,
        }
      })
      const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
      await addDoc(userActivityCollectionRef, {
        activity: UserActivityTypes.UPDATEDSETTINGS,
        type: "user",
        message: "Bank account details deleted.",
        userId: user?.uid,
        Timestamp: new Date(),
      });
      notifications.success("Bank account details deleted successfully")
    } catch (err: any) {
      notifications.error("An error occurred while deleted bank account details")
    }

  }
  // close the edit modal
  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 4 } }}>
        <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
          <Typography variant="h3" color="primary" fontWeight={600}>Direct Deposit</Typography>
          <Button variant='contained' size='small' sx={{ mb: 1, maxWidth: '180px' }} onClick={handleEdit}>{directDepositData ? 'Update bank account' : 'Add bank account'}</Button>
        </Box>
        <Divider />
        {
          directDepositData &&
          <Card sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "fit-content",
            mt: 2
          }}>
            <CardContent sx={{
              display: "flex",
              gap: { xs: 1, sm: 2 }
            }}>
              <IconButton color='primary' sx={{ p: 0 }}><AccountBalanceRounded sx={{ textAlign: 'center', fontSize: { xs: '1rem', sm: '2rem' } }} /></IconButton>
              <Box>
                <Typography variant='body1' color='primary light'><strong>Bank Account</strong></Typography>
                <Typography data-testid="bank-account-info" variant='body1'>XXXXXXXX{directDepositData?.last4}</Typography>
              </Box>
              <IconButton sx={{ p: 0 }} onClick={handleDelete} color='error'><DeleteForeverRounded sx={{ fontSize: { xs: '1rem', sm: '2rem' } }} /></IconButton>
            </CardContent>
          </Card>
        }
        {/* Edit Modal */}
      </Box>

      <BankAccountModal headerText={directDepositData ? "Update Bank Account" : "Add Bank Account"}
        submitButtonText="Save" cancelButtonText="Cancel" open={isOpen} onClose={handleCancel} />

    </>
  );
}
export default DirectDeposit;