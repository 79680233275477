import { Box, Typography, Card, CardContent, Button, Divider, ListItem, List, Grid, Link } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useRemoteConfig } from "contexts/RemoteConfig";
import { useAuth } from "contexts/User";
import { addDoc, collection } from "firebase/firestore";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { UserActivityTypes } from "services/Interfaces";
import { Interfaces } from 'services/apollo';

const enumMapping: any = {
  ACA1MedicalDrugDeductible: "Medical and Drug Deductible",
  ACA1EHBDrugOOPMax: "Drug Out of Pocket Max",
  ACA1NurseorPhysician: "Nurse or Physician Visit",
  ACA1Imaging: "Imaging",
  ACA1XraysAndDiagnosticImaging: "X-rays and Diagnostic Imaging",
  ACA1SpecialistVisit: "Specialist Visit",
  ACA1InfertilityTreatment: "Infertility Treatment",
  ACA1ChiropracticCare: "Chiropractic Care",
  ACA1LaboratoryServices: "Laboratory Services",
  ACA1PrimaryCareVisit: "Primary Care Visit",
  ACA1GenericDrugs: "Generic Drugs",
  ACA1PreferredBrandDrugs: "Preferred Brand Drugs",
  ACA1NonPreferredBrandDrugs: "Non-Preferred Brand Drugs",
  ACA1SpecialtyDrugs: "Specialty Drugs",
  ACA1WellBaby: "Well Baby Visits",
  ACA1PreventiveCare: "Preventive Care",
  ACA1UrgentCareFacilities: "Urgent Care Facilities",
  ACA1EmergencyRoomServices: "Emergency Room Services",
  ACA1EmergencyTransportation: "Emergency Transportation",
  ACA1OutpatientSurgeryServices: "Outpatient Surgery Services",
  ACA1OutpatientRehab: "Outpatient Rehab",
  ACA1InpatientServices: "Inpatient Services",
  ACA1HabilitationServices: "Habilitation Services",
  ACA1InpatientHospitalServices: "Inpatient Hospital Services",
  ACA1SkilledNursingFacility: "Skilled Nursing Facility",
  ACA1OutpatientSubstanceAbuseServices: "Outpatient Substance Abuse Services",
  ACA1InpatientSubstanceAbuseServices: "Inpatient Substance Abuse Services",
  ACA1MaternalCare: "Maternal Care",
  ACA1PrenatalAndPostnatalCare: "Prenatal and Postnatal Care",
  ACA1HomeHealthCare: "Home Health Care",
  ACA1ChildRoutineEyeExam: "Child Routine Eye Exam",
  ACA1EyeGlassesForChildren: "Eye Glasses for Children",
  //short term enums
  AnnualDeductible: "Annual Deductible",
  MaximumAnnualCopay: "Maximum Annual Copay",
  PolicyMaximum: "Policy Maximum",
  OfficeVisits: "Office Visits",
  PrescriptionDrugs: "Prescription Drugs",
  EmergencyRoom: "Emergency Room",
  Ambulance: "Ambulance",
  OutpatientSurgery: "Outpatient Surgery",
  Nursing: "Nursing",
  InpatientCopayment: "Inpatient Copayment",
  HomeHealthCare: "Home Health Care",
  MentalHealthOutpatient: "Mental Health Outpatient",
  MentalHealthInpatient: "Mental Health Inpatient",
  OutpatientRehabilitationTherapy: "Outpatient Rehabilitation Therapy",
}
function ViewDetailsModal(props: { plan: Interfaces.QuotitHealthPlanDetails, close: Function }) {
  const { firestore } = useFirebase();
  const { user } = useAuth();
  const aiScore = useRemoteConfig("aiScore").asBoolean();
  const plan = props.plan;
  async function addActivity() {
    const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
    await addDoc(userActivityCollectionRef, {
      activity: UserActivityTypes.VIEWEDHEALTHINSURANCEDETAILS,
      type: "user",
      userId: user?.uid,
      planId: props.plan.id,
      planName: props.plan.name,
      planType: props.plan.planType,
      carrierName: props.plan.carrier.name,
      Timestamp: new Date(),
    });
  }
  useEffect(() => {
    console.log("ViewHealthPlanDetails", JSON.stringify(plan))
    mixpanel.track("ViewHealthPlanDetails", {
      plan: {
        name: props.plan.name,
        carrier: props.plan.carrier.name,
        id: props.plan.id,
        type: props.plan.planType
      }
    });
    addActivity()
  })
  return (
    <Box sx={{

    }}>
      <Card sx={{
        backgroundColor: "white.main",
        p: { xs: 2, lg: 4 },
      }}>
        <CardContent sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "33px",
          padding: 0
        }}>

          <Box>
            <Typography id="view-details-modal-title" variant="h2" fontWeight={600} align="center" color={"primary.main"} sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>Plan Details</Typography>
            <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"} sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>{plan.name} from {plan.carrier.name}</Typography>
            <Typography align="center">Plan ID: <Typography component={"span"} fontWeight={600} >{plan.id}</Typography></Typography>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", ms: "row" },
            gap: { xs: 1, ms: 3 },
          }}>
            <Box width={"100%"}>
              <Typography variant="h2" align="center" sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>Plan Overview</Typography>
              <List>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Premium</Typography>
                  <Typography px={2} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}><FormattedNumber value={plan.rate} style={`currency`} currency="USD" /></Typography>
                </ListItem>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Deductible</Typography>
                  <Typography px={2} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>{plan.benefits.filter(benefit => benefit.enum === "ACA1MedicalDeductible" || benefit.enum === "ACA1MedicalDrugDeductible" || benefit.enum === "AnnualDeductible")[0].inNetwork}</Typography>
                </ListItem>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Out of Pocket Max</Typography>
                  <Typography px={2} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>{plan.benefits.filter(benefit => benefit.enum === "ACA1EHBDrugOOPMax" || benefit.enum === "MaximumAnnualCopay")[0]?.inNetwork || "Unknown"}</Typography>
                </ListItem>

              </List>
              {aiScore &&
                <Box sx={{}}>
                  <Typography variant="h2" align="center" sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>Plan Recommendation</Typography>
                  <List>
                    <ListItem>
                      <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Score</Typography>
                      <Typography px={2} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>{plan.recommendation.internalScore} / 100</Typography>
                    </ListItem>
                    {plan.recommendation.pros && plan.recommendation.pros.length > 0 &&
                      <ListItem>
                        <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Pros</Typography>
                      </ListItem>
                    }
                    {plan.recommendation.pros?.map((pro: string, index) => {
                      return (
                        <ListItem key={`pro-${index}`}>
                          <Typography px={2} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>{index+1}. {pro}</Typography>
                        </ListItem>
                      )
                    })}
                    {plan.recommendation.pros && plan.recommendation.pros.length > 0 &&
                      <ListItem>
                        <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Cons</Typography>
                      </ListItem>
                    }
                    {plan.recommendation.cons?.map((con: string, index) => {
                      return (
                        <ListItem key={`con-${index}`}>
                          <Typography px={2} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>{index+1}. {con}</Typography>
                        </ListItem>
                      )
                    })}
                  </List>
                </Box>
              }
              <Box>
                <Typography variant="h2" align="center" sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>Carrier Links</Typography>
                <List>
                  <ListItem>
                    {plan.benefitsLink && <Link href={plan.benefitsLink} target="_blank" color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Benefits</Link>}
                  </ListItem>
                  <ListItem>
                    {plan.providerLink && <Link href={plan.providerLink} target="_blank" color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Providers</Link>}
                  </ListItem>
                  <ListItem>
                    {plan.summaryOfBenefitsLink && <Link href={plan.summaryOfBenefitsLink} target="_blank" color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Summary of Benefits</Link>}
                  </ListItem>
                  <ListItem>
                    {plan.exclusionsLink && <Link href={plan.exclusionsLink} target="_blank" color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.8rem", ms: "0.9rem", lg: "1rem" } }}>Exclusions</Link>}
                  </ListItem>
                </List>
              </Box>
              {plan.disclaimer &&
                <Box>
                  <Typography variant="h2" align="center" sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>Plan Disclaimers</Typography>
                  <Box dangerouslySetInnerHTML={{ __html: plan.disclaimer }}></Box>
                </Box>
              }
            </Box>
            <Divider orientation="vertical" flexItem variant="middle" sx={{ backgroundColor: "brightOrange.main" }}></Divider>
            <Box width={"100%"} sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}>
              <Typography variant="h2" align="center" sx={{ fontSize: { xs: "1.2rem", ms: "1.5rem", lg: "1.8rem" } }}>Benefits</Typography>
              <Grid container>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={3}>
                  <Typography color={"primary"} fontWeight={600} px={2} sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>In-Network</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color={"primary"} fontWeight={600} px={2} sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>Out-of-Network</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color={"primary"} fontWeight={600} px={2} sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>Limitations and Exceptions</Typography>
                </Grid>
              </Grid>
              {plan.benefits.map((benefit) => {
                if (enumMapping[benefit.enum] && enumMapping[benefit.enum].length > 0) {
                  return (
                    <Grid container spacing={2} key={benefit.enum}>
                      <Grid item xs={3}>
                        <Typography color={"primary"} fontWeight={600} sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>{enumMapping[benefit.enum]}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>{benefit.inNetwork}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>{benefit.outOfNetwork}</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography fontSize={12} sx={{ fontSize: { xs: "0.75rem", ms: "0.9rem", lg: "1rem" }, width: "100%", wordBreak: "break-word", p: 0 }}>{benefit.exclusions === "N/A" ? "" : benefit.exclusions}</Typography>
                      </Grid>
                    </Grid>
                  );
                } else {
                  return null;
                }

              })}
            </Box>
          </Box>
          <Box sx={{ mx: "auto" }}>
            <Button variant="outlined" onClick={() => { props.close() }}>Close</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
export default ViewDetailsModal;