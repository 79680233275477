/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Grid, Button, CardContent, Card, Divider, Dialog, useTheme, useMediaQuery, Paper, Modal, CardActions } from "@mui/material";
import { useUserData } from "contexts/User/data";
import { Queries, useLazyQuery } from "services/apollo";
import { useEffect, useState } from "react";
import PreferencesModal from "components/HealthPlan/PreferencesModal";
import PersonalInfoModal from "components/User/PersonalInfoModal";
import ScheduleWithAgentModal from "components/Modals/ScheduleWithAgentModal";
import Household from "components/HealthPlan/Household";
import { useRemoteConfig } from "contexts/RemoteConfig";
import JamieCard from "components/Jamie/JamieCard";
import FindYourNextPlan from "components/HealthPlan/FindYourNextPlan";
import FindYourNextPlanQuotit from "components/HealthPlan/FindYourNextPlanQuotit";
import { Phone } from "@mui/icons-material";
import { BenefitAmounts, UserActivityTypes, WhenBenefit } from "services/Interfaces";
import { FormattedNumber } from "react-intl";
import { useAuth } from "contexts/User";
import { addDoc, collection } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { useSettings } from "contexts/Settings";
import MyApplications from "./MyApplications";

function Dashboard() {
  const { settings } = useSettings();
  const { firestore } = useFirebase();
  const { user } = useAuth()
  const theme = useTheme();
  const whenBenefit = useRemoteConfig('whenBenefit').asBoolean();
  const whenEnrollment = useRemoteConfig('whenEnrollment').asBoolean();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [planRefresh, setPlanRefresh] = useState<number>(0);
  const [openPreferences, setOpenPreferences] = useState<boolean>(false);
  const [personalInfoOpen, setPersonalInfoOpen] = useState<boolean>(false);
  const [scheduleWithAgent, setScheduleWithAgent] = useState<boolean>(false);
  const [householdOpen, setHouseholdOpen] = useState<boolean>(false);
  const [benefitsAmount, setBenefitsAmount] = useState<BenefitAmounts>({})
  const quotit = useRemoteConfig('quotit').asBoolean();
  const showServices = useRemoteConfig('services').asBoolean();
  const importantItems = useRemoteConfig('importantItems').asBoolean();
  const { answers, whenBenefits, reimbursementRequests } = useUserData();
  const [latestWhenBenefit, setLatestWhenBenefit] = useState<WhenBenefit>();
  const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.getRates, {
    notifyOnNetworkStatusChange: true,
  });
  function closeHousehold(event: any, reason: string) {
    if (reason && reason === "backdropClick")
      return;
    setHouseholdOpen(false);
  }
  async function saveHousehold() {
    if (quotit) {
      //call graphql getRates to update policies
      const effectiveDate = answers?.get("insuranceDetails.effectiveDate");
      loadRates({ variables: { effectiveDate } });
    } else {
      //call FindYourNextPlan
      setPlanRefresh(planRefresh + 1);
    }
    setHouseholdOpen(false);
  }
  function goto(uri: string) {
    if (!uri.includes("http")) {
      window.location.href = settings.PUBLIC_URL + uri;
    } else {
      window.location.href = uri;
    }
    return null;
  }
  async function addActivity() {
    const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
    await addDoc(userActivityCollectionRef, {
      activity: UserActivityTypes.CALLEDCALLCENTER,
      type: "user",
      userId: user?.uid,
      Timestamp: new Date(),
    });
  }
  useEffect(() => {
    if (rateQuery.error) {
      console.error(rateQuery.error);
    }
  }, [rateQuery]);
  useEffect(() => {
    console.log("whenBenefits", whenBenefits, whenBenefits?.docs.map((doc) => doc.data()))
    if (whenBenefits && whenBenefits?.size > 0) {
      const sorted = whenBenefits.docs.sort((a, b) => {
        const aDate = a.get("createdAt");
        const bDate = b.get("createdAt");
        if (aDate > bDate) return -1;
        if (aDate < bDate) return 1;
        return 0;
      });
      console.log("sorted whenBenefits", sorted);
      const active = sorted.filter((doc) => doc.get("status") === "new" || doc.get("status") === "active").map((doc) => doc.data());
      let amounts: BenefitAmounts = {};
      active.forEach((item) => {
        const currentReimbursements = reimbursementRequests?.docs.filter((data) => data.data().whenBenefitId === item.id);
        const approved = currentReimbursements?.filter((doc) => doc.get("status") === "Approved" || doc.get("status") === "Partially Approved").map((doc) => doc.data()).reduce((acc, data) => {
          return acc + (data?.approvedAmount || 0)
        }, 0);
        const pending = currentReimbursements?.filter((doc) => doc.get("status") === "Pending").map((doc) => doc.data()).reduce((acc, data) => {
          return acc + data.amount
        }, 0);
        amounts = {
          ...amounts,
          [item.id]: {
            approved: approved || 0,
            pending: pending || 0,
            remaining: Number(item?.maxBenefit) - Number(approved) - Number(pending),
            total: item?.maxBenefit || 0
          }
        };
      })
      setBenefitsAmount(amounts)
      setLatestWhenBenefit(sorted[0].data());
    }
  }, [reimbursementRequests, whenBenefits]);

  return (
    <Box>
      <Box sx={{
        py: 4
      }}>
        <Container maxWidth="xl">
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12}>
              <Typography variant="h1" color="primary">Hello {answers?.get("personalInfo.firstName")}</Typography>
              <Divider sx={{ backgroundColor: "brightOrange.main", my: 2 }}></Divider>

            </Grid>
            <Grid item xs={12} sm={7} >
              <JamieCard></JamieCard>
            </Grid>
            <Grid item xs={12} sm={5}>
              {!importantItems &&
                <Card>
                  <Box>
                    <Typography variant="h2" sx={{ p: 2 }}>Need Help?</Typography>
                    <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      p: 2
                    }}>
                      <Typography variant="h3">Call one of our Experts</Typography>
                      <Typography variant="body1">When&apos;s fully licensed Health Insurance Experts can provide you with free
                        advice on your health insurance options. Call an expert today!</Typography>
                      <Button variant="contained" color="secondary" href={`tel:${settings.REACT_APP_CONCIERGE_PHONE}`} onClick={addActivity}><Phone sx={{ mr: 1 }} /> {settings.REACT_APP_CONCIERGE_PHONE}</Button>
                    </Box>
                  </Box>
                </Card>
              }
              {importantItems &&
                <Card sx={{
                }}>
                  <CardContent>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1
                    }}>
                      <Typography variant="h2" sx={{ pb: 2 }}>Important Items</Typography>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                        <Typography variant="h3">Sign your Exit Letter from Company</Typography>
                        <Button size="small" variant="outlined">go</Button>
                      </Box>
                      <Divider sx={{ backgroundColor: "brightOrange.main" }} />
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                        <Typography variant="h3">Sign your NDA from Company</Typography>
                        <Button size="small" variant="outlined">go</Button>
                      </Box>
                      <Divider sx={{ backgroundColor: "brightOrange.main" }} />
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                        <Typography variant="h3">Enroll in Health Insurance</Typography>
                        <Button size="small" variant="outlined">go</Button>
                      </Box>
                      <Divider sx={{ backgroundColor: "brightOrange.main" }} />
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                      }}>
                        <Typography variant="h3">Rollover your 401K</Typography>
                        <Button size="small" variant="outlined">go</Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              }
            </Grid>
            {
              whenEnrollment &&
              <Grid item xs={12}>
                <Typography variant="h3" marginBottom={"10px"} fontWeight={800}>My Applications</Typography>
                <MyApplications />
              </Grid>
            }
            {whenBenefit && latestWhenBenefit &&  
              <Grid item xs={12} sm={12}>
                <Card sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                }}>
                  <CardContent sx={{
                    color: "white.main",
                    p: 4
                  }}>
                    <Grid container spacing={4} alignItems="stretch" sx={{
                      minHeight: "350px",
                    }}>
                      <Grid item xs={12} sm={6} sx={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "space-around",
                        // gap: 2,
                        py: 8
                      }}>
                        <Typography variant="h1" color="white.main" component={"span"}>Your When <Typography component={"span"} variant="h1" color="white.main" fontWeight={600}>Benefit</Typography></Typography>
                        <Typography color="white.main">This benefit was provided by {latestWhenBenefit.company} as part of your severance package. You are able to use your When Benefit to get reimbursed for the premium&apos;s for the health insurance policy you select. All you need to do is submit a claim with proof of payment for any health insurance policy you&apos;ve selected.</Typography>
                        {/* <Button variant="contained" color="secondary" sx={{ width: "fit-content", px: 4 }}>Review Recommendations</Button> */}
                      </Grid>
                      <Grid item xs={12} sm={3} sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around"
                      }}>
                        <Box>
                          <Typography variant="h3" color="white.main">Total Benefit</Typography>
                          <Typography variant="h2" color="secondary"><FormattedNumber style={`currency`} maximumFractionDigits={0} currency="usd" value={benefitsAmount[latestWhenBenefit?.id]?.total} /></Typography>
                        </Box>
                        <Box>
                          <Typography variant="h3" color="white.main">Remaining Benefit</Typography>
                          <Typography variant="h2" color="secondary"><FormattedNumber style={`currency`} maximumFractionDigits={0} currency="usd" value={benefitsAmount[latestWhenBenefit?.id]?.remaining} /></Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1px solid",
                        my: "auto",
                        height: "90%",
                        gap: 1,
                        borderColor: "secondary.main",
                      }}>
                        <Box sx={{ minHeight: "24px" }}></Box>
                        <Button variant="text" color="white">View Details</Button>
                        <Divider sx={{ backgroundColor: "secondary.main", width: "50%", mx: "auto" }}></Divider>
                        <Button variant="text" color="white" onClick={() => { if (window.zE) { window.zE('messenger', 'open'); } }}>Get Help</Button>
                        <Divider sx={{ backgroundColor: "secondary.main", width: "50%", mx: "auto" }}></Divider>
                        <Button variant="text" color="white">Make a Claim</Button>
                        <Box sx={{ minHeight: "24px" }}></Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            }
            <Grid item xs={12} sm={12} sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}>
              {!quotit ? <FindYourNextPlan refreshPlans={planRefresh}></FindYourNextPlan> :
                <FindYourNextPlanQuotit></FindYourNextPlanQuotit>
              }
            </Grid>
            {
              // showServices &&
              // <Grid item xs={12}>
              //   <Card sx={{
              //     width: "100%",
              //     p: 4
              //   }}>
              //     <CardContent sx={{
              //       display: "flex",
              //       flexDirection: "row",
              //       justifyContent: "space-between",
              //       gap: 4
              //     }}>
              //       <Box sx={{
              //         display: "flex",
              //         flexDirection: "column",
              //         justifyContent: "space-between",
              //         maxWidth: "40%",
              //         py: 4
              //       }}>
              //         <Box>
              //           <Typography color="primary" variant="h2" sx={{ pb: 2 }}>Find Your Next Health Insurance Plan</Typography>
              //           <Typography variant="body1" sx={{ pt: 2 }}>Shopping for your next health insurance plan can be hard.
              //             Jamie is able to help you navigate the health insurance market, and provide you with personalized
              //             recommendations!</Typography>
              //         </Box>
              //         <Box>
              //           <Button color="secondary" variant="contained" onClick={() => { navigate("/jamie/recommended-plans") }} >Review Recommendations</Button>
              //         </Box>
              //       </Box>
              //       <Box sx={{
              //       }}>
              //         <Card sx={{
              //           p: 2,
              //           backgroundColor: "beige.main",
              //           border: "1px solid",
              //           borderColor: "brightOrange.main",
              //           display: "flex",
              //           flexDirection: "column",
              //           gap: 2,
              //           maxWidth: "500px",
              //           width: "100%",
              //         }}>
              //           <Box sx={{
              //             display: "flex",
              //             flexDirection: "row",
              //             alignItems: "center",
              //             gap: 2,
              //           }}>
              //             <HealthAndSafety fontSize="large" sx={{ color: "periwinkle.main" }}></HealthAndSafety>
              //             <Typography variant="h3" color="primary">Your Recommended Plan</Typography>
              //           </Box>
              //           <Box sx={{
              //             display: "flex",
              //             flexDirection: "row",
              //             gap: 4,
              //             alignItems: "center",
              //           }}>
              //             <Box sx={{
              //               display: "flex",
              //               flexDirection: "column",

              //             }}>
              //               <Typography variant="caption">Monthly Premium</Typography>
              //               <Typography variant="h1" color="primary" fontWeight={600}>$352</Typography>
              //             </Box>
              //             <Box sx={{
              //               display: "flex",
              //               flexDirection: "column",

              //             }}>
              //               <Typography variant="caption">Deductible</Typography>
              //               <Typography variant="h1" color="primary" fontWeight={600}>$750</Typography>
              //             </Box>
              //             <Box sx={{
              //               display: "flex",
              //               flexDirection: "column",
              //               gap: 4,
              //               borderLeft: "1px solid",
              //               borderColor: "brightOrange.main",
              //               pl: 4,
              //               py: 2,
              //               justifyContent: "space-around",
              //             }}>
              //               <Box sx={{
              //                 display: "flex",
              //                 flexDirection: "column",
              //               }}>
              //                 <Typography variant="caption">Provider:</Typography>
              //                 <Typography variant="caption" fontWeight={600}>BCBS of Illinois</Typography>
              //               </Box>
              //               <Divider sx={{ backgroundColor: "brightOrange.main" }} />

              //               <Box sx={{
              //                 display: "flex",
              //                 flexDirection: "column",
              //               }}>
              //                 <Typography variant="caption">Plan:</Typography>
              //                 <Typography variant="caption" fontWeight={600}>Blue FocusCare Gold  211</Typography>
              //               </Box>
              //             </Box>
              //           </Box>
              //           <CardActions>
              //             <Button variant="contained" color="secondary" fullWidth sx={{ maxWidth: "250px", mx: "auto" }}>Select This Plan</Button>
              //           </CardActions>
              //         </Card>

              //       </Box>
              //     </CardContent>
              //     <CardActions>
              //     </CardActions>
              //   </Card>
              // </Grid>
            }
            {showServices &&
              <Grid item xs={12} sm={6}>
                <Card sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "peach.main",
                  backgroundImage: `url("/images/tulip.svg")`,
                  backgroundRepeat: "repeat-x",
                  backgroundPosition: "top left",
                }}>
                  <Box sx={{
                    backgroundImage: `url("/images/tulip.svg")`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "bottom left",
                    height: "100%",
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                  }}>
                    <CardContent sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      gap: 2
                    }}>
                      <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}>
                        <Typography color={"primary.main"} variant="h2" sx={{ pb: 2 }}>Looking for <strong>Medicare</strong>?</Typography>
                      </Box>
                      <Typography variant="body1" align="center" sx={{ pt: 2 }}>Navigating the Medicare maze can be complex, but you don&apos;t have to do it alone. Our dedicated call center is here to guide you every step of the way, ensuring you understand your options and find a Medicare solution that&apos;s right for you. Reach out today and let our experts simplify the process for you!</Typography>
                    </CardContent>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <CardActions >
                      <Button variant="contained" color="white" sx={{ mx: "auto" }}><Phone color="primary" sx={{ mr: 1 }} />{settings.REACT_APP_CONCIERGE_PHONE}</Button>
                    </CardActions>
                  </Box>
                </Card>
              </Grid>
            }
            {showServices &&
              <Grid item xs={12} sm={6} >
                <Card sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "secondary.main",
                  backgroundImage: `url("/images/tulip.svg")`,
                  backgroundRepeat: "repeat-x",
                  backgroundPosition: "top left",
                }}>
                  <Box sx={{
                    backgroundImage: `url("/images/tulip.svg")`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "bottom left",
                    height: "100%",
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                  }}>

                    <CardContent sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}>
                      <Typography color={"white.main"} variant="h2" sx={{ pb: 2 }}>Need <strong>Financial Guidance</strong>?</Typography>
                      <Typography color="white.main" variant="body1" textAlign={"center"} sx={{ pt: 2 }}>
                        When has partnered with Morgan Stanley to provide you with access to important financial resources, tools, and services.
                      </Typography>
                    </CardContent>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <CardActions>
                      <Button variant="contained" color="white" sx={{ mx: "auto" }}><Phone color="primary" sx={{ mr: 1 }} />{settings.REACT_APP_CONCIERGE_PHONE}</Button>
                    </CardActions>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      pt: 2,
                    }}>
                      <Typography sx={{ pr: 1 }} color={"white.main"}>Powered by </Typography>
                      <img width={128} alt="Morgan Stanley" src="https://www.morganstanley.com/etc.clientlibs/msdotcomr4/clientlibs/clientlib-site/resources/img/logo-white.svg"></img>
                    </Box>

                  </Box>
                </Card>
              </Grid>
            }
            {showServices &&
              <Grid item xs={12} sm={12}>
                <Card sx={{
                  width: "100%",
                  backgroundColor: "beige.main",
                  py: 4,
                }}>
                  <Box>
                    <Grid container
                      alignItems="center">
                      <Grid item xs={12} sm={6} sx={{
                      }}>
                        <Box sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          maxWidth: "400px",
                          pl: { xs: 3, ms: 8 },
                          gap: { xs: 2, sm: 4 },
                          paddingRight: 2
                        }}>
                          <Typography variant="h2" sx={{ fontSize: { xs: "1.4rem", sm: "1.5rem", ms: "1.8rem" } }}>NextJob</Typography>
                          <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 4
                          }}>
                            <Typography variant="body1" sx={{ fontSize: { xs: "0.65rem", sm: "0.7rem", ms: "0.875rem" } }}>NextJob provides multiple services absolutely free to you! NextJob
                              offers placement services, career coaching, resume building, and more. </Typography>
                          </Box>
                          <Button variant="contained" color="secondary" sx={{ fontSize: { xs: "0.65rem", sm: "0.7rem", ms: "0.875rem" }, marginBottom: { xs: 2, sm: 0 } }}>Register Now</Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{
                        py: { xs: 1, sm: 2, ms: 4 },
                        marginX: { xs: 2, sm: 0 },
                        borderLeft: { xs: "none", sm: "1px solid #ff9100" },
                        borderTop: { xs: "1px solid #ff9100", sm: "none" },
                        paddingX: { xs: 1, sm: 2 }
                      }}>
                        <Box sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignContent: "center",
                          mx: "auto",
                          width: "100%",
                          maxWidth: "350px",
                          gap: 2,
                        }}>
                          <Typography variant="h2" color={"primary"} textAlign={"center"} sx={{ fontSize: { xs: "1.4rem", sm: "1.5rem", ms: "1.8rem" } }}>Helping you land your next great job, faster. Period.</Typography>
                          <Typography variant="h2" color={"primary"} textAlign={"center"} fontWeight={600} sx={{ fontSize: { xs: "1.4rem", sm: "1.5rem", ms: "1.8rem" } }}>With the right help, job seekers improve their odds by over </Typography>
                          <Typography color="secondary" textAlign={"center"} fontWeight={800} lineHeight={1} sx={{ fontSize: { xs: 70, sm: 80, ms: 96 } }}>60%</Typography>
                        </Box>
                      </Grid>
                    </Grid>

                  </Box>
                </Card>
              </Grid>
            }
            {showServices &&
              <Grid item xs={12} sm={6} >
                <Card sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "periwinkle.main",
                  // backgroundImage: `url("/images/tulip.svg")`,
                  backgroundRepeat: "repeat-x",
                  backgroundPosition: "top left",
                }}>
                  <Box sx={{
                    // backgroundImage: `url("/images/tulip.svg")`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "bottom left",
                    height: "100%",
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                  }}>

                    <CardContent sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}>
                      <Typography color={"primary.main"} variant="h2" sx={{ pb: 2 }}><strong>401k Rollover</strong></Typography>
                      <Typography color="black" variant="body1" textAlign={"center"} sx={{ pt: 2 }}>
                        It looks like you had a 401k with your former employer. We have partnered with Morgan Stanley to make it simple
                        to rollover your 401k. Simply click the button below to get started.
                      </Typography>
                    </CardContent>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <CardActions>
                      <Button variant="contained" color="white" sx={{ mx: "auto" }}>Rollover my 401k</Button>
                    </CardActions>
                    <Typography variant="caption" sx={{ cursor: "pointer", textDecoration: "underline", mx: "auto" }} color={"black"}>Stop showing this</Typography>
                    <Box sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      pt: 2,
                    }}>
                      <Typography sx={{ pr: 1 }} color={"black"}>Powered by </Typography>
                      <img width={128} alt="Morgan Stanley" src="https://www.morganstanley.com/etc.clientlibs/msdotcomr4/clientlibs/clientlib-site/resources/img/logo-black.svg"></img>
                    </Box>

                  </Box>
                </Card>
              </Grid>
            }
            {showServices &&
              <Grid item xs={12} sm={6} >
                <Card sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "primary.main",
                  backgroundImage: `url("/images/tulip.svg")`,
                  backgroundRepeat: "repeat-x",
                  backgroundPosition: "top left",
                }}>
                  <Box sx={{
                    backgroundImage: `url("/images/tulip.svg")`,
                    backgroundRepeat: "repeat-x",
                    backgroundPosition: "bottom left",
                    height: "100%",
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                  }}>

                    <CardContent sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "center",
                      gap: 2,
                    }}>
                      <Typography color={"white.main"} variant="h2" sx={{ pb: 2 }}>Are you <strong>struggling</strong>?</Typography>
                      <Typography color="white.main" variant="body1" textAlign={"center"} sx={{ pt: 2 }}>We know that job loss can be difficult to navigate.
                        We&apos;ve partnered with Impact Suite to provide you with a free therapy session to help you through
                        this! Take advantage of this free therapy to help you work through any stress, anxiety, or depression you
                        may be facing.</Typography>
                    </CardContent>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <CardActions>
                      <Button variant="contained" color="white" sx={{ mx: "auto" }}>Schedule Your Free Session</Button>
                    </CardActions>
                  </Box>
                </Card>
              </Grid>
            }
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={"primary.main"}>
        <Container maxWidth="xl" sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          py: 8,
        }}>
          <Grid container
            alignItems={"top"}
            spacing={2}
            sx={{
              py: 2,
            }}>

            <Grid item xs={12} display={"flex"} justifyContent={"center"} sx={{

            }}>
              <Box sx={{
                maxWidth: "540px",
                textAlign: "center"
              }}>
                <Typography variant="h1" color="white.main">How Can When <Box component={"span"} fontWeight={600}>Help?</Box></Typography>
                <Typography color="white.main">When has worked with Health Insurance Experts to gather the information you need to know about Health Insurance:</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={6} sm={3} sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  justifyContent: "space-between"
                }}>
                  <Typography variant="h3" color="white.main" >Health Insurance Overview</Typography>
                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/health-insurance-overview`) }}
                  >Go</Button>
                </Grid>
                <Grid item xs={6} sm={3} sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  justifyContent: "space-between"
                }}>
                  <Typography variant="h3" color="white.main">What is COBRA?</Typography>

                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/what-is-cobra`) }}
                  >Go</Button>
                </Grid>
                <Grid item xs={6} sm={3} sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  justifyContent: "space-between"
                }}>
                  <Typography variant="h3" color="white.main">Picking a Plan</Typography>

                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/picking-a-plan`) }}
                  >Go</Button>
                </Grid>
                <Grid item xs={6} sm={3} sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  justifyContent: "space-between"
                }}>
                  <Typography variant="h3" color="white.main">Frequently Asked Questions</Typography>

                  <Button
                    color="white"
                    variant="contained"
                    sx={{
                      width: "60%",
                    }}
                    onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn/frequently-asked-questions`) }}
                  >Go</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        py: 4
      }}>
        <Container maxWidth="xl">
          <Grid container spacing={10} alignItems="center">
            <Grid item xs={12} sm={4}>
              <img src="/images/overwhelmed.svg" width="100%" alt="Feeling Overwhelmed?" />
            </Grid>
            <Grid item xs={12} sm={8} sx={{
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,

              }}>
                <Typography variant="h2" color="primary" fontWeight={600}>Feeling overwhelmed? We&apos;ve got answers!</Typography>
                <Typography>Selecting an insurance plan can be stressful. We&apos;ve put together a bunch of information that may be useful for you to find a plan. If you still need help you can also schedule a call with one of our helpful insurance experts.</Typography>
                <Box sx={{
                  display: "flex",
                  gap: 2
                }}>
                  <Button variant="contained" onClick={() => { goto(`${settings.REACT_APP_WHEN_URL}/learn`) }}>Check out our Health Insurance Guide</Button>
                  <Button variant="contained" onClick={() => { setScheduleWithAgent(true) }}>Schedule a call with an expert</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={personalInfoOpen}
        onClose={() => { setPersonalInfoOpen(false); }}
        fullScreen={isSm ? false : true}
        fullWidth
        maxWidth="lg"
      >
        <PersonalInfoModal close={() => { setPersonalInfoOpen(false); }}></PersonalInfoModal>
      </Dialog>
      <Dialog
        open={openPreferences}
        onClose={() => { setPlanRefresh(planRefresh + 1); setOpenPreferences(false); }}
        fullScreen={isSm ? false : true}
        fullWidth
        maxWidth="lg"
      >
        <PreferencesModal close={() => { setPlanRefresh(planRefresh + 1); setOpenPreferences(false); }}></PreferencesModal>
      </Dialog>
      <Dialog
        open={scheduleWithAgent}
        onClose={() => { setScheduleWithAgent(false); }}
        fullScreen={isSm ? false : true}
        maxWidth="lg"
      >
        <ScheduleWithAgentModal title="Talk to an Agent" close={() => { setScheduleWithAgent(false); }}></ScheduleWithAgentModal>
      </Dialog>

      <Modal
        open={householdOpen}
        onClose={closeHousehold}
      >
        <Paper sx={{
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          width: { xs: "100vw", sm: "50vw" },
          transform: "translate(-50%, -50%)",
          padding: "20px"
        }}>
          <Household saved={saveHousehold}></Household>
        </Paper>

      </Modal>
    </Box >
  );
}
export default Dashboard;