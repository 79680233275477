/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { Divider, FormControl, IconButton, MenuItem, Typography } from '@mui/material';
import { useAuth } from 'contexts/User';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { UserDataConverter } from 'services/Firestore/User';
import { Edit } from '@mui/icons-material';
import { useNotifications } from 'contexts/Notification';
import { UserActivityTypes } from 'services/Interfaces';
import ConfirmSubmissionDialog from './ConfirmSubmissionDialog';
import { useFirebase } from 'contexts/Firebase';

interface Field {
  label: string;
  value: string;
  field: string;
}
interface AddressInfo {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}
interface State {
  abbreviation: string;
  name: string;
}

function MailingAddress() {
  const { firestore } = useFirebase();
  const { userData } = useAuth()
  const initialFields: Field[] = [
    { field: 'address_1', label: 'Address Line 1', value: '' },
    { field: 'address_2', label: 'Address Line 2', value: '' },
    { field: 'city', label: 'City', value: '' },
    { field: 'state', label: 'State', value: '' },
    { field: 'zip_code', label: 'Zip Code', value: '' },
  ];

  const usStates: State[] = [
    { abbreviation: 'AL', name: 'Alabama' },
    { abbreviation: 'AK', name: 'Alaska' },
    { abbreviation: 'AZ', name: 'Arizona' },
    { abbreviation: 'AR', name: 'Arkansas' },
    { abbreviation: 'CA', name: 'California' },
    { abbreviation: 'CO', name: 'Colorado' },
    { abbreviation: 'CT', name: 'Connecticut' },
    { abbreviation: 'DE', name: 'Delaware' },
    { abbreviation: 'FL', name: 'Florida' },
    { abbreviation: 'GA', name: 'Georgia' },
    { abbreviation: 'HI', name: 'Hawaii' },
    { abbreviation: 'ID', name: 'Idaho' },
    { abbreviation: 'IL', name: 'Illinois' },
    { abbreviation: 'IN', name: 'Indiana' },
    { abbreviation: 'IA', name: 'Iowa' },
    { abbreviation: 'KS', name: 'Kansas' },
    { abbreviation: 'KY', name: 'Kentucky' },
    { abbreviation: 'LA', name: 'Louisiana' },
    { abbreviation: 'ME', name: 'Maine' },
    { abbreviation: 'MD', name: 'Maryland' },
    { abbreviation: 'MA', name: 'Massachusetts' },
    { abbreviation: 'MI', name: 'Michigan' },
    { abbreviation: 'MN', name: 'Minnesota' },
    { abbreviation: 'MS', name: 'Mississippi' },
    { abbreviation: 'MO', name: 'Missouri' },
    { abbreviation: 'MT', name: 'Montana' },
    { abbreviation: 'NE', name: 'Nebraska' },
    { abbreviation: 'NV', name: 'Nevada' },
    { abbreviation: 'NH', name: 'New Hampshire' },
    { abbreviation: 'NJ', name: 'New Jersey' },
    { abbreviation: 'NM', name: 'New Mexico' },
    { abbreviation: 'NY', name: 'New York' },
    { abbreviation: 'NC', name: 'North Carolina' },
    { abbreviation: 'ND', name: 'North Dakota' },
    { abbreviation: 'OH', name: 'Ohio' },
    { abbreviation: 'OK', name: 'Oklahoma' },
    { abbreviation: 'OR', name: 'Oregon' },
    { abbreviation: 'PA', name: 'Pennsylvania' },
    { abbreviation: 'RI', name: 'Rhode Island' },
    { abbreviation: 'SC', name: 'South Carolina' },
    { abbreviation: 'SD', name: 'South Dakota' },
    { abbreviation: 'TN', name: 'Tennessee' },
    { abbreviation: 'TX', name: 'Texas' },
    { abbreviation: 'UT', name: 'Utah' },
    { abbreviation: 'VT', name: 'Vermont' },
    { abbreviation: 'VA', name: 'Virginia' },
    { abbreviation: 'WA', name: 'Washington' },
    { abbreviation: 'WV', name: 'West Virginia' },
    { abbreviation: 'WI', name: 'Wisconsin' },
    { abbreviation: 'WY', name: 'Wyoming' },
  ];

  const [fields, setFields] = useState<Field[]>(initialFields);
  const [isOpen, setIsOpen] = useState<boolean>(false); // triggers for edit modal
  const [validationObject, setValidationObject] = useState<any>({});
  const [openConfirmSubmission, setOpenConfirmSubmission] = useState(false)
  const [addressInfo, setAddressInfo] = useState<AddressInfo>({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: ""
  });
  const { notifications } = useNotifications() // for showing notifications
  useEffect(() => {
    setFields(
      [
        { field: 'address_1', label: 'Address Line 1', value: userData?.data()?.address?.address1 || "" },
        { field: 'address_2', label: 'Address Line 2', value: userData?.data()?.address?.address2 || "" },
        { field: 'city', label: 'City', value: userData?.data()?.address?.city || "" },
        { field: 'state', label: 'State', value: userData?.data()?.address?.state || "" },
        { field: 'zip_code', label: 'Zip Code', value: userData?.data()?.address?.zipCode || "" },
      ]
    )
  }, [userData])

  const handleEdit = () => {
    // Initialize edited fields with current values
    setAddressInfo({
      address1: userData?.data()?.address?.address1 || "",
      address2: userData?.data()?.address?.address2 || "",
      city: userData?.data()?.address?.city || "",
      state: userData?.data()?.address?.state || "",
      zipCode: userData?.data()?.address?.zipCode || "",
    })
    setValidationObject({})
    setIsOpen(true);
  };

  const handleSave = async () => {
    if (Object.keys(validationObject)?.length === 0) {
      // update mailing address of user
      await setDoc(doc(firestore, `users/${userData?.id}`).withConverter(UserDataConverter), {
        address: {
          address1: addressInfo?.address1,
          address2: addressInfo?.address2,
          city: addressInfo?.city,
          state: addressInfo?.state,
          zipCode: addressInfo?.zipCode,
        }
      }, { merge: true })
      const userActivityCollectionRef = collection(firestore, `users/${userData?.id}/activity`);
      await addDoc(userActivityCollectionRef, {
        activity: UserActivityTypes.UPDATEDSETTINGS,
        type: "user",
        userId: userData?.id,
        Timestamp: new Date(),
      });
      setIsOpen(false);
      setOpenConfirmSubmission(false)
      setValidationObject({});
      notifications.success("Mailing Address edited successfully")
    }
  };
  // close the edit modal
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleChangeForm = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddressInfo({ ...addressInfo, [name]: event.target.value });
    if (!event.target.value && name !== "address2") {
      setValidationObject({
        ...validationObject,
        [name]: `${name?.charAt(0).toUpperCase()}${name?.slice(1)} cannot be empty`
      })
    } else if (name === "zipCode" && !event.target.value.match('(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)')) {
      setValidationObject({
        ...validationObject,
        [name]: `Invalid ${name?.charAt(0).toUpperCase()}${name?.slice(1)}`
      })
    } else {
      delete validationObject[name]
      setValidationObject(validationObject)
    }
  };

  const handleConfirmation = (e: any) => {
    e.preventDefault();
    if (
      (addressInfo.address1 !== (userData?.data()?.address?.address1 || "")) ||
      (addressInfo.address2 !== (userData?.data()?.address?.address2 || "")) ||
      (addressInfo.city !== (userData?.data()?.address?.city || "")) ||
      (addressInfo.state !== (userData?.data()?.address?.state || "")) ||
      (addressInfo.zipCode !== (userData?.data()?.address?.zipCode || ""))
    ) {
      const validation: any = {};
      Object.keys(addressInfo).forEach(function (key) {
        if (!addressInfo[key as keyof AddressInfo] && key !== "address2") {
          validation[key] = `${key?.charAt(0).toUpperCase()}${key?.slice(1)} cannot be empty`;
        } else if (key === "zipCode" && !addressInfo[key as keyof AddressInfo]?.match('(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)')) {
          validation[key] = `Invalid ${key?.charAt(0).toUpperCase()}${key?.slice(1)}`;
        }
      });
      if (Object.keys(validation).length > 0) {
        setValidationObject(validation)
        return;
      }
      if (Object.keys(validationObject)?.length === 0) {
        setOpenConfirmSubmission(true)
      }
    } else {
      handleCancel()
    }
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, sm: 4 } }}>
        <Typography variant="h3" color="primary" fontWeight={600}>Mailing Address<IconButton color='primary' sx={{ mb: 1 }} onClick={handleEdit} ><Edit /></IconButton></Typography>
        <Divider />
        <Grid container spacing={2} sx={{ py: 2, wordBreak: "break-word" }}>
          {fields.map((field) => (
            <Grid item xs={12} key={field.label}>
              <Typography variant='body1' style={{ textTransform: 'capitalize' }} ><strong>{field.label}:</strong> {field.value}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Edit Modal */}
      <Dialog open={isOpen} onClose={handleCancel} sx={{ "& .MuiPaper-root": { width: { sm: "50%", xs: "70%" } } }}>
        <DialogContent sx={{ p: { xs: 2, sm: 4 } }}>
          <Box component='form'
            onSubmit={handleConfirmation}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { sm: 3, xs: 2 }
            }}>
            <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' } }}>Edit Mailing Address</Typography>
            <FormControl>
              <TextField
                label={"Address line 1"}
                variant="outlined"
                type={'text'}
                error={Boolean(validationObject['address1'])}
                value={addressInfo?.address1}
                onChange={handleChangeForm("address1")}
                fullWidth
              />
              {validationObject['address1'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['address1']}</Typography>}
            </FormControl>
            <FormControl>
              <TextField
                label={"Address line 2"}
                variant="outlined"
                type={'text'}
                error={Boolean(validationObject['address2'])}
                value={addressInfo?.address2}
                onChange={handleChangeForm("address2")}
                fullWidth
              />
              {validationObject['address2'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['address2']}</Typography>}
            </FormControl>
            <FormControl>
              <TextField
                label={"City"}
                variant="outlined"
                type={'text'}
                error={Boolean(validationObject['city'])}
                value={addressInfo?.city}
                onChange={handleChangeForm("city")}
                fullWidth
              />
              {validationObject['city'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['city']}</Typography>}
            </FormControl>
            <FormControl>
              <TextField
                select
                label={"State"}
                variant="outlined"
                error={Boolean(validationObject['state'])}
                value={addressInfo?.state}
                onChange={handleChangeForm("state")}
                fullWidth
              >
                {usStates.map((option) => (
                  <MenuItem key={option.abbreviation} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              {validationObject['state'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['state']}</Typography>}
            </FormControl>
            <FormControl>
              <TextField
                label={"Zip Code"}
                variant="outlined"
                type={'number'}
                error={Boolean(validationObject['zipCode'])}
                value={addressInfo?.zipCode}
                onChange={handleChangeForm("zipCode")}
                fullWidth
                sx={{
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                }}
              />
              {validationObject['zipCode'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['zipCode']}</Typography>}
            </FormControl>
            <Button type='submit' variant="contained" color="primary" >Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Box>
        </DialogContent>
      </Dialog >
      {openConfirmSubmission && <ConfirmSubmissionDialog confirmSubmit={handleSave} openConfirmSubmission={openConfirmSubmission} setOpenConfirmSubmission={setOpenConfirmSubmission} />
      }
    </>
  );
}
export default MailingAddress;