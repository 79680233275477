import { Box, Button, TextField, Typography, Select, MenuItem, FormLabel, RadioGroup, Radio, FormGroup, FormControlLabel, Checkbox, TableHead, TableRow, TableBody, TableCell, Table, TableContainer, Paper, IconButton, InputLabel, FormControl} from "@mui/material";
import { Edit, RemoveCircleOutline, Check } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from "react";
import moment from "moment";
import { doc, updateDoc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuth } from "contexts/User";
import { DatePicker } from "@mui/x-date-pickers";
import { useFirebase } from "contexts/Firebase";
import { useNotifications } from "contexts/Notification";
export interface CoveredPerson {
    dob?: string;
    relationship?: string;
    gender?: string;
    pregnant?: boolean;
    tobacco?: boolean;
    name?: string;
}
interface Errors {
    name?: string;
    dob?: string;
    gender?: string;
    relationship?: string;
}

function Household(props: { saved: Function, cancel?: Function, saveOverride?: (members: { [key: string]: CoveredPerson }) => any, userId?: string }) {
    const { firestore } = useFirebase();
    const { user } = useAuth();
    const [step, setStep] = useState<string>("overview");
    const userAnswersDoc = user ? doc(firestore, 'users', props.userId || user?.uid!, "answers", props.userId || user?.uid!) : null;
    const [answers, answersLoading, firestoreError] = useDocument(
        userAnswersDoc,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [members, setMembers] = useState<{ [key: string]: CoveredPerson }>({});
    const [currentMemberName, setCurrentMemberName] = useState<any>({});
    const [currentMember, setCurrentMember] = useState<CoveredPerson>({});
    const [errors, setErrors] = useState<Errors>({});
    const { notifications } = useNotifications();

    useEffect(() => {
        const mems = answers?.get('insuranceDetails.household.members');
        if (!answersLoading && mems === undefined) {
            console.log("members is undefined")
            // create members
            updateDoc(answers?.ref!, {
                "insuranceDetails.household.members": {}
            });
        } else {
            console.log("set members")
            setMembers(mems);
        }
        console.log(answers?.data())
    }, [answers, answersLoading])
    useEffect(() => {
        if (firestoreError) {
            console.error(firestoreError);
        }
    })
    function addMember() {
        setCurrentMember({});
        setCurrentMemberName("");
        setStep("editMember");
    }
    function editMember(name: string) {
        console.log(`edit ${name} `, members[name])
        setCurrentMember(members[name]);
        setCurrentMemberName(name);
        setStep("editMember");
    }
    function deleteMember(name: string) {
        let copy = { ...members };
        //delete old record
        delete copy[name];
        setMembers({ ...copy });
    }
    function saveMember() {
        let copy = { ...members };
        if (currentMemberName !== currentMember.name) {
            //delete old record
            delete copy[currentMemberName];
        }
        setMembers({ ...copy, [currentMember.name!]: currentMember });
        setStep("overview");

    }
    const handleMemberValidationState = () => {
        const newErrors :Errors= {};
        if (!currentMember.name) newErrors.name = 'Name is required';
        if (!currentMember.dob) newErrors.dob = 'Date of Birth is required';
        if (!currentMember.gender) newErrors.gender = 'Gender is required';
        if (!currentMember.relationship) newErrors.relationship = 'Relationship is required';

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            saveMember();
        }
    };
    async function save() {
        try {
            const hasSelfMember = Object.values(members).some(member => member.relationship === 'self');
            if (!hasSelfMember) {
                notifications.error("You must have one household member with the relationship as 'self' to indicate who the primary policyholder is.");
                return;
            }

            if (typeof props.saveOverride === "function") {
                props.saveOverride(members);
            } else {
                await updateDoc(userAnswersDoc!, {
                    "insuranceDetails.household.members": members
                });
                if (typeof props.saved === "function") {
                    props.saved();
                }
            }
            return true;
        }
        catch (err: any) {
            console.error(err)
        }
    }
    if (members === undefined) {
        return (
            <Typography>Loading</Typography>
        )
    }
    if (step === "editMember" && currentMember) {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              gap: "1rem",
            }}
          >
            <Typography variant="subtitle2">
              Lets get some information about this member
            </Typography>
            <TextField
              data-testid="name-input"
              value={currentMember.name || ""}
              onChange={(e) =>
                setCurrentMember({ ...currentMember, name: e.target.value })
              }
              label="Name"
              error={!!errors.name}
              helperText={errors.name}
            ></TextField>
            <div data-testid="dob-input">
              <DatePicker
                label="Date of Birth"
                views={["year", "month", "day"]}
                value={moment(currentMember.dob, "MM/DD/YYYY") || moment()}
                onChange={(date) =>
                  date != null
                    ? setCurrentMember({
                        ...currentMember,
                        dob: moment(date).format("MM/DD/YYYY"),
                      })
                    : null
                }
              />
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="pregnant-checkbox"
                    checked={currentMember.pregnant || false}
                    onChange={(e) =>
                      setCurrentMember({
                        ...currentMember,
                        pregnant: e.target.checked,
                      })
                    }
                  />
                }
                label="Are you pregnant?"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid="tobacco-checkbox"
                    checked={currentMember.tobacco || false}
                    onChange={(e) =>
                      setCurrentMember({
                        ...currentMember,
                        tobacco: e.target.checked,
                      })
                    }
                  />
                }
                label="Do you use tobacco products?"
              />
              <Typography variant="caption">
                (e.g. cigarettes, vape pens, Zyns, etc.)
              </Typography>
            </FormGroup>
            <FormLabel id="gender-group-label">Gender</FormLabel>
            <RadioGroup
              data-testid="gender-radio-group"
              aria-labelledby="gender-group-label"
              value={currentMember.gender || ""}
              name="gender-group"
              onChange={(e) =>
                setCurrentMember({ ...currentMember, gender: e.target.value })
              }
            >
              <FormControlLabel
                value="female"
                control={<Radio data-testid="female-radio" />}
                label="Female"
              />
              <FormControlLabel
                value="male"
                control={<Radio data-testid="male-radio" />}
                label="Male"
              />
            </RadioGroup>
            {errors.gender && (
              <Typography color="error">{errors.gender}</Typography>
            )}
            <FormControl fullWidth error={!!errors.relationship}>
              <InputLabel id="relationship-select-label">
                Relationship
              </InputLabel>
              <Select
                labelId="relationship-select-label"
                data-testid="relationship-select"
                value={currentMember.relationship || ""}
                onChange={(e) =>
                  setCurrentMember({
                    ...currentMember,
                    relationship: e.target.value,
                  })
                }
                label="Relationship"
              >
                {Object.values(members).some(
                  (member) => member.relationship === "self"
                ) && currentMember.relationship !== "self" ? null : (
                  <MenuItem
                    data-testid="self-option"
                    value={"self"}
                    disabled={Object.values(members).some(
                      (member) => member.relationship === "self"
                    )}
                  >
                    Self
                  </MenuItem>
                )}
                <MenuItem
                  data-testid="spouse-option"
                  value={"spouse"}
                  disabled={Object.values(members).some(
                    (member) => member.relationship === "spouse"
                  )}
                >
                  Spouse
                </MenuItem>
                <MenuItem data-testid="child-option" value={"child"}>
                  Child
                </MenuItem>
              </Select>
              {errors.relationship && (
                <Typography color="error">{errors.relationship}</Typography>
              )}
            </FormControl>
            <Button
              variant="contained"
              onClick={(e) => {
                setStep("covered");
              }}
              color="warning"
            >
              Cancel
            </Button>
            <Button
              data-testid="continue-button"
              variant="contained"
              onClick={handleMemberValidationState}
              color="primary"
            >
              Continue
            </Button>
          </Box>
        );
    }
    return (
        <Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: 2,
            }} >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItem: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant="subtitle2">Add any covered people to your plan</Typography>
                    <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => props && props.cancel && props.cancel(false)}/>
                </Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="center">Date of Birth</TableCell>
                                <TableCell align="center">Gender</TableCell>
                                <TableCell align="center">Pregnant?</TableCell>
                                <TableCell align="center">Tobacco?</TableCell>
                                <TableCell align="center">Edit</TableCell>
                                <TableCell align="center">Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(members).map(([key, member], index) => {
                                if (key === 'undefined' || Object.keys(member).length === 0) {
                                    return null;
                                } else {
                                    return (
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {member.name}
                                            </TableCell>
                                            <TableCell align="center">{member.dob}</TableCell>
                                            <TableCell align="center">{member.gender}</TableCell>
                                            <TableCell align="center">{member.pregnant === true && <Check />}</TableCell>
                                            <TableCell align="center">{member.tobacco === true && <Check />}</TableCell>
                                            <TableCell align="center">
                                                <IconButton onClick={() => { editMember(member.name!); }} aria-label="edit">
                                                    <Edit />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton disabled={member.relationship === "self"} onClick={() => { deleteMember(member.name!); }} aria-label="delete">
                                                    <RemoveCircleOutline />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!Object.values(members).some(member => member.relationship === 'self') && (
                    <Typography variant="subtitle2" color="warning">Please make sure that you have added yourself to your household. If you are shopping for someone else, you should set the primary policyholder&apos;s relationship as &quot;self&quot;.</Typography>
                )}
                <Button data-testid="add-new-person-button" variant="contained" onClick={e => { addMember(); }} color="secondary" >Add a new person</Button>
                <Button data-testid="continue-button" variant="contained" onClick={e => { save(); }} color="primary" >Continue</Button>

            </Box>
        </Box >
    )
}
export default Household;
