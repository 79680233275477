import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid'
import { ConfirmationModal } from 'components/common/ConfirmationModal';
import { useFirebase } from 'contexts/Firebase';
import { useNotifications } from 'contexts/Notification';
import { useAuth } from 'contexts/User';
import { collection, doc, query, updateDoc, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore';
import { FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router';
import { applicationConverter } from 'services/Firestore/Application';
import { Application } from 'services/Interfaces';

const applicationStatus = (status: string) => {
    let value = ""
    switch (status) {
        case "draft":
            value = "Draft";
            break;
        case "submitted":
            value = "Submitted";
            break;
        case "in progress":
            value = "In Progress";
            break;
        case "Additional information needed":
            value = "Additional information needed"
            break;
        case "pending signature":
            value = "Pending Signature";
            break;
        case "signature received":
            value = "Signature received";
            break;
        case "accepted":
            value = "Accepted";
            break;
        case "denied":
            value = "Denied";
            break;
        case "cancelled":
            value = "Cancelled";
            break;
        case "expired":
            value = "Expired";
            break;
        default:
            value = "Draft";
    }
    return value;
}

function MyApplications() {
    const { firestore } = useFirebase();
    const { user } = useAuth()
    const navigate = useNavigate();
    const { notifications } = useNotifications();
    const [openCancelApplicationForm, setOpenCancelApplicationForm] = useState<boolean>(false)
    const [cancelApplicationId, setCancelApplicationId] = useState<string | undefined | null>()
    const [applications, applicationsLoading,] = useCollection<Application>(
        user ?
            query(
                collection(firestore, `users/${user?.uid}/applications`),
                where("status", "not-in", ["cancelled", "expired", "denied"])
            ).withConverter(applicationConverter)
            : null,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const cancelApplication = async () => {
        if (!user?.uid || !cancelApplicationId) {
            return;
        }
        try {
            await updateDoc(doc(firestore, "users", user?.uid, "applications", cancelApplicationId), {
                status: "cancelled"
            })
            notifications.success("Application cancelled successfully")
            setOpenCancelApplicationForm(false);
            setCancelApplicationId(undefined);
        } catch (error) {
            notifications.error("An error occurred while canceling application")
        }
    }

    const applicationColumns = [
        {
            field: "id",
            headerName: "Application ID",
            minWidth: 200,
            sortable: false,
            renderHeader: () => <span data-testid="column-application-id">Application ID</span>
        },
        {
            field: "plan.name",
            headerName: "Plan name",
            minWidth: 200,
            flex: 1,
            sortable: false,
            renderHeader: () => <span data-testid="column-plan-name">Plan name</span>,
            renderCell: (params: any) => <Typography data-testid={`plan-name-${params.id}`}>{params?.row?.plan?.name}</Typography>
        },
        {
            field: "effectiveDate",
            headerName: "Effective date",
            width: 150,
            type: 'date',
            valueGetter: (params: any) => params.row.plan.effectiveDate ? new Date(params.row.plan.effectiveDate) : null,
            valueFormatter: (params: any) => params.value ? moment(params.value).format('MM/DD/YYYY') : "",
            renderHeader: () => <span data-testid="column-effective-date">Effective date</span>,
        },
        {
            field: "monthlyPremium",
            headerName: "Monthly Premium",
            width: 150,
            sortable: false,
            renderHeader: () => <span data-testid="column-monthly-premium">Monthly Premium</span>,
            renderCell: (params: any) => {
                return <span data-testid={`monthly-premium-${params.id}`}><FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={params?.row?.plan?.rate || 0} /></span>
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            renderHeader: () => <span data-testid="column-status">Status</span>,
            renderCell: (params: any) => <Typography data-testid={`test-application-status-${params?.row?.status}`}>{applicationStatus(params?.row?.status)}</Typography>
        },
        {
            field: "createdOn",
            headerName: "Created On",
            width: 250,
            type: 'date',
            valueGetter: (params: any) => params.row.createdAt ? new Date(params.row.createdAt) : null,
            valueFormatter: (params: any) => params.value ? moment(params.value).format('MM/DD/YYYY') : "",
            renderHeader: () => <span data-testid="column-effective-date">Created On</span>,
        },
        {
            field: "actions",
            headerName: "Actions",
            minWidth: 250,
            sortable: false,
            renderHeader: () => <span data-testid="column-actions">Actions</span>,
            renderCell: (params: any) => {
                console.log(params.row.status);

                if (params.row.status === "draft") {
                    return (
                        <Box sx={{ display: "flex", gap: "2px" }} data-testid={`actions-${params.id}`}>
                            <Button data-testid={`continue-${params.id}`} variant="contained" color="primary" onClick={() => navigate(`/account/applications/${params.row.id}`)}>Continue</Button>
                            <Button data-testid={`cancel-${params.id}`} variant="contained" color="primary" onClick={() => { setCancelApplicationId(params.row.id); setOpenCancelApplicationForm(true) }}>Cancel</Button>
                        </Box>
                    )
                } else if (params.row.status === "Additional information needed") {
                    return (
                        <Box sx={{ display: "flex", gap: "2px" }} data-testid={`actions-${params.id}`}>
                            <Button data-testid={`view-${params.id}`} variant="contained" color="primary" onClick={() => navigate(`/account/applications/${params.row.id}`)}>View</Button>
                            <Button data-testid={`attach-document-${params.id}`} variant="contained" color="primary" onClick={() => navigate(`/account/applications/${params.row.id}`)}>Attach document</Button>
                        </Box>
                    )
                } else {
                    return (
                        <Box sx={{ display: "flex", gap: "2px" }} data-testid={`actions-${params.id}`}>
                            <Button data-testid={`view-${params.id}`} variant="contained" color="primary" onClick={() => navigate(`/account/applications/${params.row.id}`)}>View</Button>
                        </Box>
                    )
                }
            }
        }
    ];
    return (
        <>
            <DataGrid
                getRowId={(row) => row.id}
                loading={applicationsLoading}
                sx={{
                    background: "white"
                }}
                autoHeight
                columns={applicationColumns}
                rows={applications?.docs.map(_ => _.data()) || []}
                data-testid="applications-datagrid"
                pagination
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 3
                        }
                    },
                    sorting: {
                        sortModel: [{ field: 'createdOn', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={[3, 10, 20]}
            />
            <ConfirmationModal
                headingText='Cancel Application'
                bodyText='Are you sure you want to cancel Application?'
                btnText='Confirm'
                open={openCancelApplicationForm}
                onClose={() => setOpenCancelApplicationForm(false)}
                onSave={() => cancelApplication()}
            />
        </>
    )
}

export default MyApplications
